import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectUser } from 'reduxStore/auth/selectors'
import { AUTH_DEFAULT_PATH } from './constants'

const AutoRedirect: React.FC = () => {
  const navigate = useNavigate()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (user) {
      navigate(AUTH_DEFAULT_PATH)
    } else {
      navigate('/auth')
    }
  }, [user])

  return null
}

export default AutoRedirect
