import DynamicForm from 'components/form'
import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import React from 'react'
import { Player } from 'types/types'
import { playersValidationSchema } from '../constants'
import { usePlayersModal } from '../hooks/usePlayersModal'

interface PlayerFormModalProps {
  isOpen: boolean
  onClose: () => void
  data?: Player
  mode: TableActionMode
}

export const PlayerFormModal = ({ isOpen, onClose, data, mode }: PlayerFormModalProps) => {
  const formik = useFormik({
    initialValues: {
      ...data,
      banned_from: data?.banned_from || '',
      banned_until: data?.banned_until || '',
    },
    validationSchema: playersValidationSchema,
    onSubmit: (values) => handleOnSubmit(values),
    enableReinitialize: true,
  })

  const { handleOnSubmit, isLoading, formFields } = usePlayersModal({ data, mode, onClose, formik })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={handleClose}
      title={mode === 'edit' ? 'Edit Player' : 'Create Player'}
      onOk={formik.handleSubmit}
      onCancel={handleClose}
      isLoading={isLoading}
      okText={mode === 'edit' ? 'Save Changes' : 'Create'}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
