import React from 'react'

import { Box, Button, Flex } from '@chakra-ui/react'

import { useGetUsers } from 'api/queries'
import GenericTable from 'components/genericTable'
import { EditButton, RemoveButton } from 'components/genericTable/ActionButtons'
import ListLayout from 'components/list/ListLayout'
import { useSelector } from 'react-redux'
import { selectUser } from 'reduxStore/auth/selectors'
import { OfficeUser } from 'types/types'
import { UserFormModal } from './components/UserFormModal'
import UserRemoveDialog from './components/UserRemoveDialog'
import { useUsersTable } from './hooks/useUsersTable'

export const UsersView = () => {
  const { data } = useGetUsers()
  const OfficeUser = useSelector(selectUser)

  const { actionState, columns } = useUsersTable()
  const { setMode, hideMode, hideCurrentMode } = actionState

  const renderActionColumn = (user: OfficeUser) => {
    if (OfficeUser?.id === user.id) {
      return null
    }

    return (
      <Flex justify='flex-start' align='center'>
        <EditButton onClick={() => setMode('edit', user)} />
        <RemoveButton onClick={() => setMode('remove', user)} />
      </Flex>
    )
  }

  return (
    <ListLayout>
      <Box w='fit-content'>
        <Button colorScheme='blue' onClick={() => setMode('create')}>
          Create User
        </Button>
      </Box>
      <GenericTable
        title='User List'
        columns={columns}
        data={data}
        renderActionColumn={renderActionColumn}
      />
      <UserFormModal
        mode={actionState.actionMode}
        isOpen={actionState.create || actionState.edit}
        onClose={hideCurrentMode}
        data={actionState.actionData}
      />
      <UserRemoveDialog
        isOpen={actionState.remove}
        onClose={() => hideMode('remove')}
        id={actionState.actionData?.id}
      />
    </ListLayout>
  )
}
