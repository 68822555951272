import { Box } from '@chakra-ui/react'
import { ChatComponent, ChatContext } from '@spinbet/common-ui-components'
import { BrandSelector } from 'components/selectors/BrandSelector'
import React from 'react'
import { Brand } from 'types/types'

interface ChatSelectorProps {
  data: Brand[]
  selectedBrand: Brand | null
  onBrandSelect: (brandId: string) => void
}

const ChatSelector: React.FC<ChatSelectorProps> = ({ data, selectedBrand, onBrandSelect }) => {
  const { showChat } = ChatContext.useChat()
  const handleOnBrandSelect = (brandId: string) => {
    onBrandSelect(brandId)
    showChat()
  }

  return (
    <Box>
      <BrandSelector data={data} handleSelect={handleOnBrandSelect} brand={selectedBrand} />
      <Box h='1rem' />
      {selectedBrand && <ChatComponent style={{ height: 'calc(100vh - 200px)' }} />}
    </Box>
  )
}

export default ChatSelector
