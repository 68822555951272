import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'

interface ActionModalProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  children: React.ReactNode
  onOk?: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
  isLoading?: boolean
}

export const ActionModal: React.FC<ActionModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  onOk,
  onCancel,
  okText = 'OK',
  cancelText = 'Cancel',
  isLoading = false,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {title && <ModalHeader color={'brand.500'}>{title}</ModalHeader>}
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {onOk && (
            <Button colorScheme='blue' mr={3} onClick={onOk} isLoading={isLoading}>
              {okText}
            </Button>
          )}
          {onCancel && (
            <Button variant='ghost' onClick={onCancel}>
              {cancelText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
