import { CloseIcon } from '@chakra-ui/icons'
import { Box, IconButton, Image } from '@chakra-ui/react'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { fileToBase64 } from 'utils/common'
import { ensurePngFormat } from 'utils/images'
import { dropzoneAcceptedFiles } from './constants'

interface ImageUploadProps {
  value: string
  onChange: (fileBase64: string) => void
}

export const ImageUpload = ({ value, onChange }: ImageUploadProps) => {
  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]

    ensurePngFormat(file)
      .then((convertedFile) => {
        fileToBase64(convertedFile).then((base64) => {
          onChange(base64)
        })
      })
      .catch((error) => console.error('Error converting image:', error))
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: dropzoneAcceptedFiles,
    noClick: !!value,
  })

  const removeImage = (event: React.MouseEvent) => {
    event.stopPropagation()
    onChange('')
  }

  return (
    <Box
      {...getRootProps()}
      position='relative'
      textAlign='center'
      overflow='hidden'
      borderRadius='md'
      boxShadow='md'
      border={value ? 'none' : '2px dashed gray'}
      cursor={!value ? 'pointer' : 'default'}
      bg={value ? 'white' : 'transparent'}
      _hover={{ borderColor: 'gray.400' }}
    >
      <input {...getInputProps()} />
      {!value ? (
        <Box p={4} height='200px' display='flex' alignItems='center' justifyContent='center'>
          Drag and drop an image here, or click to select one
        </Box>
      ) : (
        <Box position='relative' width='100%'>
          <Image src={value} alt='Uploaded Image' width='100%' height='200px' objectFit='cover' />
          <IconButton
            icon={<CloseIcon />}
            position='absolute'
            top='4px'
            right='4px'
            zIndex='2'
            size='sm'
            colorScheme='red'
            aria-label='Remove Image'
            onClick={removeImage}
          />
        </Box>
      )}
    </Box>
  )
}
