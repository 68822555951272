import { useToast } from '@chakra-ui/react'
import { useRemoveBrand } from 'api/queries'
import ConfirmationDialog from 'components/dialog/ConfirmationDialog'

interface BrandRemoveDialogProps {
  isOpen: boolean
  onClose: () => void
  id: number
}

export const BrandRemoveDialog: React.FC<BrandRemoveDialogProps> = ({ isOpen, onClose, id }) => {
  const removeMutation = useRemoveBrand()
  const toast = useToast()

  const handleRemoveData = () => {
    removeMutation.mutate(id, {
      onSuccess: () => {
        onClose()
        toast({
          title: 'Brand deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleRemoveData}
      isLoading={removeMutation.isPending}
      title='Delete Brand'
      body="Are you sure? You can't undo this action afterwards."
      confirmText='Delete'
    />
  )
}
