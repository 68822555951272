import { Image } from '@chakra-ui/react'
import { CustomSelector } from '@spinbet/common-ui-components'
import { SelectorOption } from '@spinbet/common-ui-components/dist/components/Chat/CustomSelector'
import { Brand } from 'types/types'

export const createBrandOptions = (data: Brand[]): SelectorOption[] => {
  return data.map((item) => {
    return {
      id: item.id?.toString(),
      label: item.name,
      icon: () => (
        <Image src={item.logo} alt={item.name} w='20px' h='20px' mr='5px' objectFit={'cover'} />
      ),
    }
  })
}

export interface BrandSelectorProps {
  data: Brand[]
  brand: Brand
  handleSelect: (id: string) => void
}

export const BrandSelector = ({ data, brand, handleSelect }: BrandSelectorProps) => {
  return (
    <CustomSelector
      options={createBrandOptions(data)}
      selectedOptionId={brand?.id?.toString() || ''}
      onSelect={handleSelect}
      placeholder='Select brand'
    />
  )
}
