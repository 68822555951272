import { Flex } from '@chakra-ui/react'
import { Channel } from '@spinbet/common-ui-components'
import { useGetBrands, useGetChannels } from 'api/queries'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'reduxStore/auth/selectors'
import { Brand, OfficeUser } from 'types/types'
import ChatWrapper from './ChatWrapper'

export interface ChatState {
  brand: Brand | null
  channels: Channel[]
}

export type ChatRecord = Record<string, ChatState>

export const emptyChatState: ChatState = {
  brand: null,
  channels: [],
}

const ChatView: React.FC = () => {
  const user: OfficeUser = useSelector(selectUser)
  const { data: brands } = useGetBrands()
  const { data: channels } = useGetChannels()
  const [chatState, setChatState] = useState<ChatRecord>({
    '1': emptyChatState,
    '2': emptyChatState,
    '3': emptyChatState,
  })

  const handleBrandSelect = (chatId: string, brandId: string) => {
    const brand = brands?.find((brand) => brand?.id.toString() === brandId)
    setChatState({
      ...chatState,
      [chatId]: {
        ...chatState[chatId],
        brand,
      },
    })
  }

  return (
    <Flex
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      direction='row'
      justify='space-between'
      wrap='wrap'
    >
      {Object.keys(chatState).map((chatId) => {
        const chat = chatState[chatId]
        const chatChannels = channels?.filter((channel) => chat.brand?.id === channel.brand_id)
        return (
          <ChatWrapper
            key={chatId}
            chatId={chatId}
            brand={chat.brand}
            channels={chatChannels}
            user={user}
            brands={brands || []}
            handleSelectBrand={handleBrandSelect}
          />
        )
      })}
    </Flex>
  )
}

export default ChatView
