import { Box, Button, Flex } from '@chakra-ui/react'
import { useGetPlayers } from 'api/queries'
import { ToggleBanButton } from 'components/banForm/ToggleBanButton'
import FilterOptions from 'components/filters/FilterOptions'

import GenericTable from 'components/genericTable'
import { EditButton, RemoveButton } from 'components/genericTable/ActionButtons'
import ListLayout from 'components/list/ListLayout'
import { SearchBar } from 'components/navbar/searchBar/SearchBar'
import useSearchState from 'hooks/useSearchState'
import React from 'react'
import { Player, PlayerFilter } from 'types/types'
import { PlayerFormModal } from './components/PlayerFormModal'
import { PlayerRemoveDialog } from './components/PlayerRemoveDialog'
import useFilters from './hooks/useFilters'
import { usePlayersTable } from './hooks/usePlayersTable'

const PlayersView = () => {
  const { actionState, columns } = usePlayersTable()
  const { setMode, actionMode, actionData, hideCurrentMode, hideMode } = actionState
  const { onSearchTextChange, searchText, debouncedText } = useSearchState()

  const { filterItems, filterState, updateFilter, filtersQuery } = useFilters()

  const { data, isFetching, fetchNextPage, metadata } = useGetPlayers({
    search: {
      field: 'username',
      value: debouncedText,
    },
    filters: filtersQuery,
  })

  const renderActionColumn = (data: Player) => {
    return (
      <Flex justify='flex-start' align='center'>
        <EditButton onClick={() => setMode('edit', data)} />
        <RemoveButton onClick={() => setMode('remove', data)} />
        <ToggleBanButton playerId={data.id} bannedUntil={data.banned_until} />
      </Flex>
    )
  }

  const renderFilters = () => {
    return (
      <FilterOptions
        filterOptions={filterItems}
        onFilterChange={(updatedFilters: PlayerFilter[]) =>
          updateFilter('boolFields', updatedFilters)
        }
        selectedFilterIds={filterState.boolFields}
      />
    )
  }

  return (
    <ListLayout>
      <SearchBar searchValue={searchText} onSearchChange={onSearchTextChange} />
      {renderFilters()}
      <GenericTable
        columns={columns}
        data={data}
        renderActionColumn={renderActionColumn}
        title={'Players'}
        hasMore={data.length < metadata.total}
        isLoading={isFetching}
        fetchMoreData={fetchNextPage}
      />
      <PlayerFormModal
        mode={actionMode}
        isOpen={actionMode === 'create' || actionMode === 'edit'}
        onClose={hideCurrentMode}
        data={actionData}
      />
      <PlayerRemoveDialog
        isOpen={actionState.remove}
        onClose={() => hideMode('remove')}
        id={actionState.actionData?.id}
      />
    </ListLayout>
  )
}

export default PlayersView
