import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query'
import { PaginatedData } from 'api/types'
import { useState } from 'react'

export interface FetchFunctionParams {
  limit: number
  skip: number
}

export interface PaginatedQueryParams<TData> extends Partial<UseInfiniteQueryOptions<TData[]>> {
  key: QueryKey
  fetchFunction: (params: FetchFunctionParams) => Promise<PaginatedData<TData>>
  limit?: number
  onErrorCallback?: (error: Error) => void
  updateKeyWithUser?: boolean
}

export type PaginationMetadata = Pick<PaginatedData<any>, 'total' | 'limit' | 'skip'>

const usePaginatedQuery = <TData = any>({
  key,
  fetchFunction,
  limit = 10,
  onErrorCallback,
  updateKeyWithUser = true,
  ...restOfProps
}: PaginatedQueryParams<TData>) => {
  const [metadata, setMetadata] = useState<PaginationMetadata | null>({
    total: 0,
    limit,
    skip: 0,
  })
  const keyWithUser = updateKeyWithUser ? [...key, 'user'] : key

  const queryResult = useInfiniteQuery<TData[]>({
    ...restOfProps,
    initialPageParam: 0,
    queryKey: keyWithUser,
    queryFn: async ({ pageParam = 0 }) => {
      const skip = Number(pageParam) * limit
      const response = await fetchFunction({ limit, skip })
      // Update metadata state
      setMetadata({ total: response.total, limit: response.limit, skip: response.skip })
      return response.data
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < limit) {
        return undefined
      }

      return allPages.length
    },
    // Keep the select function if you need it for transforming data
    select: (data) => {
      return {
        pages: [data.pages.flat()],
        result: data.pages.flat(),
        pageParams: data.pageParams,
      }
    },
  })

  return {
    ...queryResult,
    data: queryResult.data?.pages.flat() ?? [],
    fetchNextPage: () => {
      if (queryResult.hasNextPage) {
        queryResult.fetchNextPage()
      }
    },
    metadata,
  }
}

export default usePaginatedQuery
