import { feathersApp } from 'api/endpoints'
import { useDispatch } from 'react-redux'
import { logout } from 'reduxStore/auth/slice'

const useLogout = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
    feathersApp.logout()
  }

  return handleLogout
}

export default useLogout
