import { useToast } from '@chakra-ui/react'
import { useCreateProfanity, useUpdateProfanity } from 'api/queries'
import { DynamicFormField } from 'components/form/types'
import { FormikProps } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import { Profanity } from 'types/types'

export interface UseProfanitiesModalProps {
  mode: TableActionMode
  onClose: () => void
  data?: Profanity
  formik: FormikProps<Profanity>
}

export const useProfanitiesModal = ({ mode, onClose, data }: UseProfanitiesModalProps) => {
  const toast = useToast()
  const createQuery = useCreateProfanity()
  const updateQuery = useUpdateProfanity()

  const mutationQuery = mode === 'edit' ? updateQuery : createQuery

  const handleOnSubmit = async (values: Profanity) => {
    try {
      await mutationQuery.mutateAsync({
        ...data,
        ...values,
      })
      toast({
        title: mode === 'edit' ? 'Profanity is updated.' : 'Profanity is created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const formFields: DynamicFormField[] = [{ id: 'word', name: 'Word', type: 'input' }]

  return {
    handleOnSubmit,
    isLoading: mutationQuery.isPending,
    formFields,
  }
}
