import Providers from 'components/Providers/Providers'
import AppRoutes from 'components/routes/AppRoutes'
import React from 'react'

const App = () => {
  return (
    <Providers>
      <AppRoutes />
    </Providers>
  )
}

export default App
