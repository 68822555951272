import { Box, Button, HStack, Textarea, VStack } from '@chakra-ui/react'
import { useGetBrands, useGetChannels } from 'api/queries'
import { BrandSelector } from 'components/selectors/BrandSelector'
import { ChannelSelector } from 'components/selectors/ChannelSelector'
import { AiOutlinePushpin } from 'react-icons/ai'

interface PinnedMessageFormProps {
  onSubmit: (message: string) => void
  isSubmitting: boolean
  onBrandIdChange: (id: number) => void
  onChannelIdChange: (id: number) => void
  message: string
  setMessage: (message: string) => void
  brandId: number
  channelId: number
}

export const PinnedMessageForm = ({
  onSubmit,
  isSubmitting,
  onBrandIdChange,
  onChannelIdChange,
  message,
  setMessage,
  brandId,
  channelId,
}: PinnedMessageFormProps) => {
  const { data: brands } = useGetBrands()
  const { data: channels } = useGetChannels()

  const selectedBrand = brands?.find((b) => b.id === brandId)
  const selectedChannel = channels?.find((c) => c.id === channelId)

  return (
    <Box p={4} bg='white' borderRadius='lg' boxShadow='sm' border='1px' borderColor='gray.200'>
      <VStack spacing={4} align='stretch'>
        <HStack spacing={2} align='flex-start'>
          <Box w='200px'>
            <BrandSelector
              data={brands}
              brand={selectedBrand}
              handleSelect={(id) => {
                onBrandIdChange(Number(id))
                onChannelIdChange(0)
              }}
            />
          </Box>

          <Box flex={1}>
            <ChannelSelector
              data={channels}
              brand={selectedBrand}
              channel={selectedChannel}
              handleSelect={(id) => onChannelIdChange(Number(id))}
            />
          </Box>
        </HStack>

        <HStack spacing={2}>
          <Box flex={1}>
            <Textarea
              placeholder='Enter a message to pin...'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              color='blackAlpha.800'
              bg='gray.50'
              _focus={{
                bg: 'white',
                borderColor: 'blue.500',
              }}
              _hover={{
                bg: 'gray.100',
              }}
              rows={4}
              resize='vertical'
            />
          </Box>
          <Button
            onClick={() => onSubmit(message)}
            colorScheme='blue'
            isLoading={isSubmitting}
            leftIcon={<AiOutlinePushpin />}
            isDisabled={!brandId || !channelId}
            px={6}
            _hover={{
              transform: 'translateY(-1px)',
              boxShadow: 'sm',
            }}
          >
            Pin
          </Button>
        </HStack>
      </VStack>
    </Box>
  )
}
