import { Brand, OfficeUser } from 'types/types'
import { v4 as uuidv4 } from 'uuid'

export type UpdateUserPayload = Pick<OfficeUser, 'first_name' | 'last_name' | 'role' | 'username'>

export type UpdateBrandPayload = Pick<Brand, 'name' | 'logo'>

export const createUpdateUserPayload = (data: OfficeUser): UpdateUserPayload => {
  return {
    first_name: data.first_name,
    last_name: data.last_name,
    role: data.role,
    username: data.email,
  }
}

export const createUpdateBrandPayload = (data: Brand): UpdateBrandPayload => {
  return {
    logo: data.logo,
    name: data.name,
  }
}

export interface CustomQuery {
  search?: {
    field: string // Field to search in
    value: string // Search term
  }
  sort?: { [key: string]: number } // e.g., { createdAt: -1 }
  page?: number
  offset?: number
  filters?: {
    [key: string]: any // Custom filters
  }
  limit?: number
  skip?: number
}

export function convertToFeathersQuery(customQuery: CustomQuery): any {
  const { search, sort, filters, limit, skip } = customQuery

  let query: any = { ...filters }

  // Search handling
  if (search?.field && search.value) {
    query[`${search.field}[$ilike]`] = `${search.value}%`
  }

  // Pagination handling
  if (limit !== undefined) {
    query.$limit = limit
  }
  if (skip !== undefined) {
    query.$skip = skip
  }

  // Sorting handling
  if (sort) {
    Object.keys(sort).forEach((key) => {
      query[`$sort[${key}]`] = sort[key]
    })
  }

  return query
}

export const normalizeQueryParams = (params: any) => {
  return JSON.stringify(params, Object.keys(params).sort())
}
