import { USER_ROLES } from 'constants/common'
import * as Yup from 'yup'

export const userValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().oneOf(USER_ROLES, 'Invalid role').required('Role is required'),
  username: Yup.string().required('Username is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
})
