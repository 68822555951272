import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import AdminLayout from 'layouts/admin/AdminDashboard'

import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import SignIn from 'views/auth/SignIn'
import AutoRedirect from './AutoRedirect'
import { AppPaths, AUTH_DEFAULT_PATH } from './constants'

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <AutoRedirect />
      <Routes>
        <Route path={AppPaths.Auth + '/*'} element={<SignIn />} />
        <Route
          path={AppPaths.Admin + '/*'}
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        />
        <Route path={'/'} element={<Navigate replace to={AUTH_DEFAULT_PATH} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
