import { Box, useToast, VStack } from '@chakra-ui/react'
import { useCreatePinMessage, useGetPinnedMessage, useRemovePinMessage } from 'api/queries'
import { useEffect, useState } from 'react'
import { CurrentPinnedMessage } from './CurrentPinnedMessage'
import { PinnedMessageForm } from './PinnedMessageForm'

const PinnedMessageView = () => {
  const [brandId, setBrandId] = useState(0)
  const [channelId, setChannelId] = useState(0)
  const [message, setMessage] = useState('')

  const toast = useToast()
  const { data, isPending } = useGetPinnedMessage(brandId, channelId)
  const createPinMutation = useCreatePinMessage()
  const removePinMutation = useRemovePinMessage()

  const handleSaveSuccess = () => {
    toast({
      title: 'Message pinned',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  const handleSaveError = () => {
    toast({
      title: 'Error pinning message',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  }

  const handleRemoveSuccess = () => {
    toast({
      title: 'Pin removed',
      status: 'info',
      duration: 3000,
      isClosable: true,
    })
  }

  const handleRemoveError = () => {
    toast({
      title: 'Error removing pin',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  }

  useEffect(() => {
    if (data?.message) {
      setMessage(data.message)
    }
  }, [data?.message])

  const handleSave = (message: string) => {
    createPinMutation.mutate(
      {
        message,
        brand_id: Number(brandId),
        channel_id: Number(channelId),
      },
      {
        onSuccess: handleSaveSuccess,
        onError: handleSaveError,
      }
    )
  }

  const handleRemove = () => {
    if (data?.id) {
      removePinMutation.mutate(data.id, {
        onSuccess: handleRemoveSuccess,
        onError: handleRemoveError,
      })
    }
  }

  return (
    <Box py={20} px={10} borderWidth='1px' borderRadius='lg' bg='white' shadow='sm'>
      <VStack spacing={4} align='stretch'>
        <CurrentPinnedMessage message={data} onRemove={handleRemove} isLoading={isPending} />
        <PinnedMessageForm
          onSubmit={handleSave}
          isSubmitting={createPinMutation.isPending}
          onBrandIdChange={setBrandId}
          onChannelIdChange={setChannelId}
          message={message}
          setMessage={setMessage}
          brandId={brandId}
          channelId={channelId}
        />
      </VStack>
    </Box>
  )
}

export default PinnedMessageView
