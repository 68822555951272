import { useToast } from '@chakra-ui/react'
import { useUpdatePlayer } from 'api/queries'
import { DynamicFormField } from 'components/form/types'
import { FormikProps } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import { Player, PlayerRoles } from 'types/types'

export interface UsePlayersModalProps {
  mode: TableActionMode
  onClose: () => void
  data?: Player
  formik: FormikProps<Player>
}

export const usePlayersModal = ({ mode, onClose, data }: UsePlayersModalProps) => {
  const toast = useToast()

  const updateQuery = useUpdatePlayer()

  const handleOnSubmit = async (values: Player) => {
    try {
      await updateQuery.mutateAsync({
        id: values?.id,
        role: values.role,
      })
      toast({
        title: mode === 'edit' ? 'Player is updated.' : 'Player is created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const roles = Object.values(PlayerRoles).map((role) => ({ label: role, value: role }))

  const formFields: DynamicFormField[] = [
    { id: 'role', name: 'Role', type: 'select', options: roles },
  ]

  return {
    handleOnSubmit,
    isLoading: updateQuery.isPending,
    formFields,
  }
}
