import { useCallback, useEffect, useState } from 'react'
import { UseFiltersStateResult } from 'types/types'

const useFiltersState = <T>(initialState: T, defaultFilters: T): UseFiltersStateResult<T> => {
  const [filterState, setFilterState] = useState<T>(initialState)

  const updateFilter = useCallback(<K extends keyof T>(field: K, value: T[K]) => {
    setFilterState((prevFilterState) => ({
      ...prevFilterState,
      [field]: value,
    }))
  }, [])

  const clearFilters = useCallback(() => {
    setFilterState(defaultFilters)
  }, [defaultFilters])

  useEffect(() => {
    setFilterState(initialState)
  }, [initialState])

  return {
    filterState,
    updateFilter,
    clearFilters,
    setFilterState,
  }
}

export default useFiltersState
