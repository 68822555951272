import { useToast } from '@chakra-ui/react'
import { useRemoveProfanity } from 'api/queries'
import ConfirmationDialog from 'components/dialog/ConfirmationDialog'

interface ProfanitiesRemoveDialogProps {
  isOpen: boolean
  onClose: () => void
  id: number
}

export const ProfanitiesRemoveDialog: React.FC<ProfanitiesRemoveDialogProps> = ({
  isOpen,
  onClose,
  id,
}) => {
  const removeMutation = useRemoveProfanity()
  const toast = useToast()

  const handleRemoveData = () => {
    removeMutation.mutate(id, {
      onSuccess: () => {
        onClose()
        toast({
          title: 'Profanity deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleRemoveData}
      isLoading={removeMutation.isPending}
      title='Delete Profanity'
      body="Are you sure? You can't undo this action afterwards."
      confirmText='Delete'
    />
  )
}
