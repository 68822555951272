import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import reduxStore, { reduxPersistor } from 'reduxStore/store'
import ThemeProvider from './ThemeProvider'

export interface ProvidersProps extends PropsWithChildren {}

export const queryClient = new QueryClient()

const Providers = ({ children }: ProvidersProps) => {
  return (
    <Provider store={reduxStore}>
      <PersistGate persistor={reduxPersistor}>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default Providers
