import { Box, Portal, useDisclosure } from '@chakra-ui/react'

import Navbar from 'components/navbar/NavbarAdmin'
import routes from 'components/routes/routes'
import Sidebar from 'components/sidebar/Sidebar'
import { SidebarContext } from 'contexts/SidebarContext'
import React, { FC, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

interface DashboardProps {
  [x: string]: any
}

const AdminDashboard: FC<DashboardProps> = (props) => {
  const [fixed] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const { onOpen } = useDisclosure()

  const location = useLocation()

  const activeRouteName = findActiveRouteName(routes, location.pathname)
  const adminRoutes = filterAndMapRoutes(routes, '/admin')

  return (
    <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
      <Sidebar routes={routes} display='none' {...props} />
      <ContentArea
        onOpen={onOpen}
        fixed={fixed}
        activeRouteName={activeRouteName}
        adminRoutes={adminRoutes}
      />
    </SidebarContext.Provider>
  )
}

const ContentArea: FC<{
  onOpen: () => void
  fixed: boolean
  activeRouteName: string
  adminRoutes: JSX.Element[]
}> = ({ onOpen, fixed, activeRouteName, adminRoutes }) => (
  <Box
    float='right'
    minHeight='100vh'
    height='100%'
    overflow='auto'
    position='relative'
    maxHeight='100%'
    w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
    maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
    transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
    transitionDuration='.2s, .2s, .35s'
    transitionProperty='top, bottom, width'
    transitionTimingFunction='linear, linear, ease'
  >
    <Portal>
      <Navbar
        onOpen={onOpen}
        logoText=''
        brandText={activeRouteName}
        fixed={fixed}
        message=''
        secondary={false}
      />
    </Portal>
    <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
      <Routes>{adminRoutes}</Routes>
    </Box>
  </Box>
)

const findActiveRouteName = (routes: RoutesType[], pathname: string): string => {
  const activeRoute = routes.find((route) => pathname.includes(route.path))
  return activeRoute ? activeRoute.name : ''
}

const filterAndMapRoutes = (routes: RoutesType[], layout: string): JSX.Element[] =>
  routes
    .filter((route) => route.layout === layout)
    .map((route, key) => <Route key={key} path={route.path} element={<route.component />} />)

export default AdminDashboard
