import { useState } from 'react'

export type TableActionMode = 'create' | 'edit' | 'remove' | 'view'

export type ActionVisibilityState = Record<TableActionMode, boolean>

export interface TableActionState<T> extends ActionVisibilityState {
  actionMode: TableActionMode
  actionData: T | undefined
  isCurrentModeVisible: boolean
  setMode: (mode: TableActionMode, data?: T) => void
  hideMode: (mode: TableActionMode) => void
  hideCurrentMode: () => void
}

export const useActionState = <T>(): TableActionState<T> => {
  const [actionMode, setActionMode] = useState<TableActionMode>('view')
  const [actionData, setActionData] = useState<T | undefined>(undefined)
  const [actionState, setActionState] = useState<ActionVisibilityState>({
    create: false,
    edit: false,
    remove: false,
    view: false,
  })

  const setMode = (mode: TableActionMode, data?: T) => {
    if (data !== undefined) {
      setActionData(data)
    }

    setActionState({
      ...actionState,
      [mode]: true,
    })
    setActionMode(mode)
  }

  const hideMode = (mode: TableActionMode) => {
    setActionState({
      ...actionState,
      [mode]: false,
    })
    setActionMode('view')
  }

  const hideCurrentMode = () => {
    hideMode(actionMode)
    setActionData(undefined)
  }

  return {
    actionMode,
    actionData,
    setMode,
    hideMode,
    isCurrentModeVisible: actionState[actionMode],
    hideCurrentMode,
    ...actionState,
  }
}
