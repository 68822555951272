import { useCallback, useState } from 'react'
import { UseSearchStateResult } from 'types/types'
import useDebouncedRef from './useDebouncedRef'

const SEARCH_DEBOUNCE_DELAY = 500

const useSearchState = (): UseSearchStateResult => {
  const [searchText, setSearchText] = useState('')
  const [debouncedText, setDebouncedText] = useState('')

  const debouncedSearch = useDebouncedRef(
    (newText) => setDebouncedText(newText),
    SEARCH_DEBOUNCE_DELAY
  )

  const onSearchTextChange = (text: string) => {
    setSearchText(text)
    debouncedSearch(text)
  }

  const clearSearchText = useCallback(() => {
    setSearchText('')
    setDebouncedText('')
    debouncedSearch.cancel()
  }, [debouncedSearch])

  return {
    searchText,
    debouncedText,
    onSearchTextChange,
    clearSearchText,
    setDebouncedSearchText: debouncedSearch,
  }
}

export default useSearchState
