import { DebouncedFunc } from 'lodash'

export enum UserRoles {
  Support = 'support',
  Moderator = 'moderator',
  Admin = 'admin',
}

export interface AuthenticationPayload {
  iat: number
  exp: number
  aud: string
  sub: string
  jti: string
}

export interface Authentication {
  strategy: string
  payload: AuthenticationPayload
}

export interface OfficeUser {
  id: number
  username: string
  email: string
  first_name: string
  last_name: string
  role: string
  created_at: string
  updated_at: string
}

export interface LoginResponse {
  accessToken: string
  authentication: Authentication
  user: OfficeUser
}

export type OfficeUserForm = OfficeUser

export interface Brand {
  id: number
  name: string
  logo: string
  created_at: string
  updated_at: string
  min_wager_amount: number
  min_wager_currency: string
}

export interface Channel {
  id: number
  name: string
  logo: string
  brand_id: number
  created_at: string
  updated_at: string
}

export interface UploadData {
  base64: string
  key: string
  mimeType: string
}

export interface UploadResponse {
  url?: string
  key: string
}

export enum PlayerRoles {
  Player = 'player',
  Moderator = 'player_moderator',
}
export interface Player {
  banned_from: string | null
  banned_until: string | null
  brand_id: number
  created_at: string
  currency: string
  id: number
  total_wager_amount: number | null
  updated_at: string
  user_id: number
  username: string
  vip_settings: VipSettings | null
  wagering_details?: {
    wager_amount: number
    min_required_wager_amount: number
  }
  ban_details: {
    banned_from: string
    banned_until: string
  }
  role: PlayerRoles
}

export interface VipIcon {
  path: string
}

export interface VipSettings {
  id: number
  icon: VipIcon
  name: string
  color: string
  level: number
}

export interface BanPlayerRequest {
  player_id: number
  ban_period: BanPeriod
}

export interface BannedPlayer {
  id: number
  player_id: number
  banned_until: string
  created_at: string
  updated_at: string
}

export enum BanPeriod {
  OneHour = '1h',
  OneDay = '1d',
  SevenDays = '7d',
  OneMonth = '1m',
}

export interface LoginPayload {
  email: string
  password: string
}

export interface UseSearchStateResult {
  searchText: string
  debouncedText: string
  onSearchTextChange: (text: string) => void
  clearSearchText: () => void
  setDebouncedSearchText: DebouncedFunc<(text: string) => void>
}

export interface UseFiltersStateResult<T> {
  filterState: T
  setFilterState: (state: T) => void
  updateFilter: <K extends keyof T>(key: K, value: T[K]) => void
  clearFilters: () => void
}

export interface Profanity {
  id: number
  word: string
  created_at: string
  updated_at: string
}

export enum PlayerFilter {
  Banned_Until = 'banned_until',
  Role = 'role',
}

export interface PlayersFilterState {
  boolFields: PlayerFilter[]
}

export interface Whitelist {
  id: number
  link: string
  created_at: string
  updated_at: string
}

export interface PinnedMessage {
  id: number
  message: string
  created_at: string
  updated_at: string
}

export interface PinnedMessageBody {
  message: string
  brand_id: number
  channel_id: number
}
