import DynamicForm from 'components/form'
import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import React from 'react'
import { Profanity } from 'types/types'
import { profanitiesValidationSchema } from '../constants'
import { useProfanitiesModal } from '../hooks/useProfanitiesModal'

interface ProfanitiesFormModalProps {
  isOpen: boolean
  onClose: () => void
  data?: Profanity
  mode: TableActionMode
}

export const ProfanitiesFormModal = ({
  isOpen,
  onClose,
  data,
  mode,
}: ProfanitiesFormModalProps) => {
  const formik = useFormik({
    initialValues: {
      ...data,
      word: data?.word || '',
    },
    validationSchema: profanitiesValidationSchema,
    onSubmit: (values) => handleOnSubmit(values),
    enableReinitialize: true,
  })

  const { handleOnSubmit, isLoading, formFields } = useProfanitiesModal({
    data,
    mode,
    onClose,
    formik,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={handleClose}
      title={mode === 'edit' ? 'Edit Profanity' : 'Create Profanity'}
      onOk={formik.handleSubmit}
      onCancel={handleClose}
      isLoading={isLoading}
      okText={mode === 'edit' ? 'Save Changes' : 'Create'}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
