import { Icon } from '@chakra-ui/react'
import { AiFillPushpin, AiOutlineSafety } from 'react-icons/ai'
import { FiFrown } from 'react-icons/fi'
import { LuGamepad2 } from 'react-icons/lu'
import { MdBarChart, MdChat, MdWifiChannel } from 'react-icons/md'
import { SiBrandfolder } from 'react-icons/si'
import BrandsView from 'views/admin/brands/BrandsView'
import ChannelsView from 'views/admin/channels/ChannelsView'
import ChatView from 'views/admin/chat/ChatView'
import PinnedMessageView from 'views/admin/pinned-message/PinnedMessageView'
import PlayersView from 'views/admin/players/PlayersView'
import ProfanitiesView from 'views/admin/profanities/ProfanitiesView'
import { UsersView } from 'views/admin/users/UsersView'
import WhitelistView from 'views/admin/whitelist/Whitelist'
import { AppPaths } from './constants'

const routes = [
  {
    name: 'Chat',
    layout: '/admin',
    path: AppPaths.Chat,
    icon: <Icon as={MdChat} width='20px' height='20px' color='inherit' />,
    component: ChatView,
  },
  {
    name: 'Brands',
    layout: '/admin',
    path: AppPaths.Brands,
    icon: <Icon as={SiBrandfolder} width='20px' height='20px' color='inherit' />,
    component: BrandsView,
  },

  {
    name: 'Users',
    layout: '/admin',
    path: AppPaths.Users,
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: UsersView,
  },
  {
    name: 'Channels',
    layout: '/admin',
    path: AppPaths.Channels,
    icon: <Icon as={MdWifiChannel} width='20px' height='20px' color='inherit' />,
    component: ChannelsView,
  },
  {
    name: 'Players',
    layout: '/admin',
    path: AppPaths.Players,
    icon: <Icon as={LuGamepad2} width='20px' height='20px' color='inherit' />,
    component: PlayersView,
  },
  {
    name: 'Profanity Filters',
    layout: '/admin',
    path: AppPaths.Profanities,
    icon: <Icon as={FiFrown} width='20px' height='20px' color='inherit' />,
    component: ProfanitiesView,
  },
  {
    name: 'Whitelist links',
    layout: '/admin',
    path: AppPaths.Whitelist,
    icon: <Icon as={AiOutlineSafety} width='20px' height='20px' color='inherit' />,
    component: WhitelistView,
  },
  {
    name: 'Pinned message',
    layout: '/admin',
    path: AppPaths.PinnedMessage,
    icon: <Icon as={AiFillPushpin} width='20px' height='20px' color='inherit' />,
    component: PinnedMessageView,
  },
]

export default routes
