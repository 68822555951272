import { useActionState } from 'hooks/useActionState'
import { OfficeUser } from 'types/types'

export const useUsersTable = () => {
  const actionState = useActionState<OfficeUser>()

  const columns = [
    { id: 'id', Header: 'ID', accessor: 'id' },
    { id: 'email', Header: 'Email', accessor: 'email' },
    { id: 'username', Header: 'Username', accessor: 'username' },
    { id: 'first_name', Header: 'First Name', accessor: 'first_name' },
    { id: 'last_name', Header: 'Last Name', accessor: 'last_name' },
    { id: 'role', Header: 'Role', accessor: 'role' },
    { id: 'actions', Header: 'Actions', accessor: 'actions' },
  ]

  return {
    actionState,
    columns,
  }
}
