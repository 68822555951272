import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'

interface SearchBarProps {
  placeholder?: string
  borderRadius?: string | number
  onSearchChange: (value: string) => void
  searchValue: string
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = 'Search...',
  borderRadius = '30px',
  onSearchChange,
  searchValue,
}) => {
  const searchIconColor = useColorModeValue('gray.700', 'white')
  const inputBg = useColorModeValue('white', 'navy.900')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value)
  }

  const clearInput = () => {
    onSearchChange('')
  }

  return (
    <InputGroup size='lg' width='100%'>
      <Input
        pr='4.5rem'
        variant='filled'
        fontSize='md'
        bg={inputBg}
        color={'blackAlpha.700'}
        fontWeight='500'
        _placeholder={{ color: 'gray.400' }}
        borderRadius={borderRadius}
        placeholder={placeholder}
        value={searchValue}
        onChange={handleInputChange}
      />
      <InputRightElement
        width='4.5rem'
        height='100%'
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        mr={5}
      >
        {searchValue ? (
          <IconButton
            aria-label='Clear search'
            size='sm'
            icon={<CloseIcon />}
            onClick={clearInput}
            variant='ghost'
          />
        ) : (
          <SearchIcon color={searchIconColor} mr={5} />
        )}
      </InputRightElement>
    </InputGroup>
  )
}
