import deepEqual from 'deep-equal'

export const toggleFilter = <T = string>(filterId: T, selectedFilterIds: T[]): T[] => {
  return selectedFilterIds.includes(filterId)
    ? selectedFilterIds.filter((id) => id !== filterId)
    : [...selectedFilterIds, filterId]
}

export const checkHasActiveFilters = (filters: object, initialFilters: object): boolean => {
  return (
    !deepEqual(filters, initialFilters) &&
    Object.values(filters).some((value) => {
      return Array.isArray(value) && value.length > 0
    })
  )
}

export const mapBoolFieldsToQuery = (boolFields: string[]) => {
  const filters: { [key: string]: any } = {}
  const DAY_MS = 24 * 60 * 60 * 1000

  boolFields.forEach((field) => {
    filters[field] = { $gt: new Date().getTime() - DAY_MS }
  })

  return filters
}
