import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useLogin } from 'api/queries'

import { useState } from 'react'

import { AppPaths } from 'components/routes/constants'
import { useFormik } from 'formik'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from 'reduxStore/auth/slice'
import * as Yup from 'yup'
import AuthLayout from '../layout'

const SignInPage = () => {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorSecondary = 'gray.400'

  const textColorBrand = useColorModeValue('brand.500', 'white')
  const brandStars = useColorModeValue('brand.500', 'brand.400')

  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const { mutate, isPending } = useLogin()
  const navigate = useNavigate()

  const { values, setFieldValue, isValid, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values: any) => {
      mutate(
        { email: values.email, password: values.password },
        {
          onSuccess: (data) => {
            dispatch(setUser(data.user))
            navigate(AppPaths.Admin)
          },
        }
      )
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().min(8).required(),
    }),
  })

  const renderLoginForm = () => (
    <FormControl>
      <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
        Email<Text color={brandStars}>*</Text>
      </FormLabel>
      <Input
        isRequired={true}
        variant='auth'
        fontSize='sm'
        ms={{ base: '0px', md: '0px' }}
        type='email'
        placeholder='email@spinbet.com'
        mb='24px'
        fontWeight='500'
        size='lg'
        value={values.email}
        onChange={(e) => setFieldValue('email', e.target.value)}
      />
      <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
        Password<Text color={brandStars}>*</Text>
      </FormLabel>
      <InputGroup size='md'>
        <Input
          isRequired={true}
          fontSize='sm'
          placeholder='Min. 8 characters'
          mb='24px'
          size='lg'
          type={show ? 'text' : 'password'}
          variant='auth'
          value={values.password}
          onChange={(e) => setFieldValue('password', e.target.value)}
        />
        <InputRightElement display='flex' alignItems='center' mt='4px'>
          <Icon
            color={textColorSecondary}
            _hover={{ cursor: 'pointer' }}
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      <Flex justifyContent='space-between' align='center' mb='24px'>
        <FormControl display='flex' alignItems='center'>
          <Checkbox id='remember-login' colorScheme='brand' me='10px' />
          <FormLabel
            htmlFor='remember-login'
            mb='0'
            fontWeight='normal'
            color={textColor}
            fontSize='sm'
          >
            Keep me logged in
          </FormLabel>
        </FormControl>
        <Link href='/auth/forgot-password'>
          <Text color={textColorBrand} fontSize='sm' w='124px' fontWeight='500'>
            Forgot password?
          </Text>
        </Link>
      </Flex>
      <Button
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        w='100%'
        h='50'
        mb='24px'
        type='submit'
        disabled={!isValid || isPending}
        isLoading={isPending}
        onClick={() => handleSubmit()}
      >
        Sign In
      </Button>
    </FormControl>
  )

  return (
    <AuthLayout>
      <Flex direction='column' align='center' justify='center' minH='100vh' w='100%'>
        <Flex
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          px={{ base: '25px', md: '0px' }}
          py={{ base: '10px', md: '20px' }}
          borderRadius='15px'
          bg='transparent'
          align='center'
        >
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
            <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: '100%', md: '420px' }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: 'auto', lg: 'unset' }}
            me='auto'
            mb={{ base: '20px', md: 'auto' }}
          >
            {renderLoginForm()}
          </Flex>
        </Flex>
      </Flex>
    </AuthLayout>
  )
}

export default SignInPage
