import { Box, FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import FormErrorsDisplay from './FormErrorsDisplay'
import { DynamicFormProps } from './types'

const DynamicForm = <T extends Record<string, any>>({
  formik,
  formFields,
  onValuesChange,
}: DynamicFormProps<T>) => {
  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(formik.values)
    }
  }, [formik.values, onValuesChange])

  const getErrorMessage = (fieldId: string): string | undefined => {
    const error = formik.errors[fieldId]
    if (typeof error === 'string') {
      return error
    }
    return undefined
  }

  return (
    <Box>
      {formFields.map((field) => {
        if ('jsx' in field) {
          return <Box key={field.key}>{field.jsx}</Box>
        }

        return (
          <FormControl
            key={field.id}
            isInvalid={Boolean(formik.errors[field.id] && formik.touched[field.id])}
            {...field.formControlProps}
          >
            <FormLabel color={'brand.400'} htmlFor={field.id}>
              {field.name}
            </FormLabel>
            {field.type === 'input' && (
              <Input
                color={'brand.400'}
                id={field.id}
                placeholder={field.placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[field.id]}
                {...field.inputProps}
              />
            )}
            {field.type === 'select' && (
              <Select
                id={field.id}
                placeholder={field.placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[field.id]}
                color={'brand.500'}
                {...field.selectProps}
              >
                {field.options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
            {field.type === 'custom' && field.customComponent && (
              <field.customComponent {...(field.customProps as Record<string, unknown>)} />
            )}
            <FormErrorMessage>{getErrorMessage(field.id)}</FormErrorMessage>
          </FormControl>
        )
      })}
      <FormErrorsDisplay errors={formik.errors} />
    </Box>
  )
}

export default DynamicForm
