import { useToast } from '@chakra-ui/react'
import { useCreateUser, useUpdateUser } from 'api/queries'
import { DynamicFormField } from 'components/form/types'
import { USER_ROLES } from 'constants/common'
import { TableActionMode } from 'hooks/useActionState'
import { OfficeUser, OfficeUserForm } from 'types/types'

export interface UseUsersModalProps {
  mode: TableActionMode
  onClose: () => void
  data?: OfficeUser
}

export const useUsersModal = ({ mode, onClose, data }: UseUsersModalProps) => {
  const toast = useToast()
  const createUserQuery = useCreateUser()
  const updateUserQuery = useUpdateUser()

  const mutationQuery = mode === 'edit' ? updateUserQuery : createUserQuery

  const handleOnSubmit = async (values: OfficeUserForm) => {
    try {
      await mutationQuery.mutateAsync({
        ...data,
        ...values,
      })
      toast({
        title: mode === 'edit' ? 'User updated.' : 'User created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const formFields: DynamicFormField[] = [
    {
      id: 'email',
      name: 'Email',
      type: 'input',
      placeholder: 'Email',
      inputProps: {
        isDisabled: mode === 'edit',
      },
    },
    {
      id: 'username',
      name: 'Username',
      type: 'input',
      placeholder: 'Username',
      inputProps: {
        isDisabled: mode === 'edit',
      },
    },
    {
      id: 'first_name',
      name: 'First Name',
      type: 'input',
      placeholder: 'First Name',
    },
    {
      id: 'last_name',
      name: 'Last Name',
      type: 'input',
      placeholder: 'Last Name',
    },
    {
      id: 'role',
      name: 'Role',
      type: 'select',
      options: USER_ROLES.map((role) => ({ label: role, value: role })),
    },
  ]

  return {
    handleOnSubmit,
    isLoading: mutationQuery.isPending,
    formFields,
  }
}
