import { debounce } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'

interface UseInfiniteScrollProps {
  fetchMoreData: () => void
  hasMore: boolean
  isLoading: boolean
}

export const useInfiniteScroll = ({
  fetchMoreData,
  hasMore,
  isLoading,
}: UseInfiniteScrollProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(
    debounce(() => {
      if (!scrollRef.current || !hasMore || isLoading) return
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
      if (scrollHeight - scrollTop <= clientHeight * 1.5) {
        fetchMoreData()
      }
    }, 200),
    [fetchMoreData, hasMore, isLoading]
  )

  useEffect(() => {
    const scrollElement = scrollRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  return scrollRef
}
