import { ChakraProvider } from '@chakra-ui/react'
import {
  razedTheme,
  spinbetTheme,
  ThemeProvider as ProviderComponent,
} from '@spinbet/common-ui-components'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { selectBrand } from 'reduxStore/auth/selectors'

type Theme = Record<string, any>

export const brandThemeMap: Record<number, Theme> = {
  11: spinbetTheme,
  6: razedTheme,
}

const getTheme = (brandId: number): Theme => {
  return brandThemeMap[brandId] || spinbetTheme
}

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const brand = useSelector(selectBrand)
  const theme = getTheme(brand?.id)

  return (
    <ChakraProvider theme={theme}>
      <ProviderComponent theme={theme}>{children}</ProviderComponent>
    </ChakraProvider>
  )
}

export default ThemeProvider
