import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { SPINBET_AUTH_TOKEN } from 'constants/common'
import qs from 'qs'
import { unauthorizedAction } from 'reduxStore/auth/common'
import { apiConfig, UNAUTHORIZED_STATUS } from './constants'

let store: any

export const injectStore = (_store: any) => {
  store = _store
}

class ApiService {
  service: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    const service = axios.create({
      ...config,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })

    this.service = service
    this.service.interceptors.response.use(
      this.handleSuccess.bind(this),
      this.handleError.bind(this)
    )
    this.service.interceptors.request.use(
      async (interceptorConfig) => {
        const token = localStorage.getItem(SPINBET_AUTH_TOKEN)

        if (token) {
          interceptorConfig.headers.Authorization = `Bearer ${token}`
        }

        return interceptorConfig
      },
      (error) => Promise.reject(error)
    )
  }

  handleSuccess(response: AxiosResponse) {
    return response
  }

  handleError(error = {} as any) {
    if (!error?.response as unknown as AxiosError) return

    if (error.response?.status === UNAUTHORIZED_STATUS && store) {
      store.dispatch({ type: unauthorizedAction.type })
    }

    return Promise.reject(error)
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.service.get<T>(url, config)
  }

  async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.post<T>(url, data, config)
  }

  async put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.put<T>(url, data, config)
  }

  async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.patch<T>(url, data, config)
  }

  async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.service.delete<T>(url, config)
  }
}

export default new ApiService(apiConfig)
