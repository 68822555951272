import { Box } from '@chakra-ui/react'
import { Brand, Channel, ChatContext, OfficeUser } from '@spinbet/common-ui-components'
import { BackOfficeEndpoint } from 'api/constants'
import { SPINBET_AUTH_TOKEN } from 'constants/common'

import React from 'react'
import ChatSelector from './components/ChatSelector'

export interface ChatWrapperProps {
  chatId: string
  brand: Brand | null
  channels: Channel[]
  user: OfficeUser
  brands: Brand[]
  handleSelectBrand: (chatId: string, brandId: string) => void
}

const ChatWrapper = ({
  chatId,
  brand,
  user,
  channels,
  brands,
  handleSelectBrand,
}: ChatWrapperProps) => {
  const token = localStorage.getItem(SPINBET_AUTH_TOKEN)

  return (
    <Box key={chatId} flex='1' mx='5'>
      <ChatContext.ChatProvider
        brand={brand}
        channels={channels || []}
        messageSocketURL={BackOfficeEndpoint.webSocket}
        openRulesPopup={() => {}}
        authPayload={{
          strategy: 'jwt',
          accessToken: token || '',
        }}
      >
        <ChatSelector
          data={brands || []}
          selectedBrand={brand}
          onBrandSelect={(brandId) => handleSelectBrand(chatId, brandId)}
        />
      </ChatContext.ChatProvider>
    </Box>
  )
}

export default ChatWrapper
