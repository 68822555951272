import { IconButtonProps, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import { BanPlayerModal } from './BanPlayerModal'

import { useToggleBanPlayer } from 'api/queries'
import ConfirmationDialog from 'components/dialog/ConfirmationDialog'
import { BanButton } from 'components/genericTable/ActionButtons'
import { isNilOrEmpty } from 'utils/common'

interface ToggleBanButtonProps extends Partial<IconButtonProps> {
  playerId: number
  bannedUntil?: string | null
}

export const ToggleBanButton: React.FC<ToggleBanButtonProps> = ({
  playerId,
  bannedUntil,
  ...rest
}) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: toggleBan } = useToggleBanPlayer()
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure()
  const isBanned = !isNilOrEmpty(bannedUntil)

  const handleUnban = async () => {
    try {
      await toggleBan({ player_id: playerId, ban_period: undefined })
      onConfirmClose()
      toast({
        title: 'Player is unbanned.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <BanButton bannedUntil={bannedUntil} onClick={isBanned ? onConfirmOpen : onOpen} {...rest} />
      <BanPlayerModal isOpen={isOpen} onClose={onClose} playerId={playerId} />
      <ConfirmationDialog
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        onConfirm={handleUnban}
        isLoading={false}
        title='Unban Player'
        body='Are you sure you want to unban this player?'
        confirmText='Unban'
        confirmColorScheme='green'
      />
    </>
  )
}
