import { Image } from '@chakra-ui/react'
import { CustomSelector } from '@spinbet/common-ui-components'
import { SelectorOption } from '@spinbet/common-ui-components/dist/components/Chat/CustomSelector'
import { Brand, Channel } from 'types/types'

export const createChannelOptions = (data: Channel[], brand?: Brand): SelectorOption[] => {
  const filteredData = data.filter((item) => item.brand_id === brand?.id)

  return filteredData.map((item) => {
    return {
      id: item.id?.toString(),
      label: item.name,
      icon: () => (
        <Image src={item.logo} alt={item.name} w='20px' h='20px' mr='5px' objectFit={'cover'} />
      ),
    }
  })
}

export interface ChannelSelectorProps {
  data: Channel[]
  brand?: Brand
  channel?: Channel
  handleSelect: (id: string) => void
}

export const ChannelSelector = ({ data, brand, channel, handleSelect }: ChannelSelectorProps) => {
  return (
    <CustomSelector
      options={createChannelOptions(data, brand)}
      selectedOptionId={channel?.id?.toString() || ''}
      onSelect={handleSelect}
      placeholder='Select channel'
    />
  )
}
