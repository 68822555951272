import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from 'components/Providers/Providers'
import usePaginatedQuery from 'hooks/usePaginatedQuery'
import { useStableQueryKey } from 'hooks/useStableQueryKey'
import {
  BannedPlayer,
  BanPlayerRequest,
  Brand,
  Channel,
  LoginPayload,
  OfficeUser,
  OfficeUserForm,
  PinnedMessageBody,
  Player,
  Profanity,
  UploadData,
  Whitelist,
} from 'types/types'
import { isNilOrEmpty } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'
import {
  BANNED_PLAYERS_QUERY_KEY,
  BRANDS_QUERY_KEY,
  CHANNELS_QUERY_KEY,
  PINNED_MESSAGE_QUERY_KEY,
  PLAYERS_QUERY_KEY,
  PROFANITIES_QUERY_KEY,
  USERS_QUERY_KEY,
  WHITELIST_QUERY_KEY,
} from './constants'
import {
  banPlayerRequest,
  createBrandRequest,
  createChannelRequest,
  createPinnedMessageRequest,
  createPlayerRequest,
  createProfanityRequest,
  createUserRequest,
  createWhitelistRequest,
  getBannedPlayersRequest,
  getBrandsRequest,
  getChannelRequest,
  getPinnedMessageRequest,
  getPlayersRequest,
  getProfanitiesRequest,
  getUsersRequest,
  getWhitelistRequest,
  loginRequest,
  removeBrandRequest,
  removeChannelRequest,
  removePinnedMessageRequest,
  removePlayerRequest,
  removeProfanityRequest,
  removeUserRequest,
  removeWhitelistRequest,
  unbanPlayerRequest,
  updateBrandRequest,
  updateChannelRequest,
  updatePlayerRequest,
  updateProfanityRequest,
  updateUserRequest,
  updateWhitelistRequest,
  uploadFileRequest,
} from './endpoints'
import { convertToFeathersQuery, CustomQuery } from './utils'

export const uploadLogoOrReturnLink = async (logo: string): Promise<string> => {
  if (logo.startsWith('data:image')) {
    const uploadData = {
      base64: logo,
      key: uuidv4(),
      mimeType: 'image/png',
    }
    try {
      const response = await uploadFileRequest(uploadData)

      return response.url
    } catch (error) {
      console.error('Failed to upload logo:', error)
      throw new Error('Logo upload failed')
    }
  } else {
    return logo
  }
}

export const useLogin = () => {
  return useMutation({
    mutationFn: async (loginPayload: LoginPayload) => {
      return await loginRequest(loginPayload)
    },
  })
}

export const useGetUsers = () => {
  return usePaginatedQuery<OfficeUser>({
    key: [USERS_QUERY_KEY],
    fetchFunction: async ({ limit, skip }) => {
      const query = convertToFeathersQuery({ limit, skip })
      return await getUsersRequest(query)
    },
  })
}

export const useCreateUser = () => {
  return useMutation({
    mutationFn: async (body: OfficeUserForm) => {
      const response = await createUserRequest(body)
      queryClient.refetchQueries({
        queryKey: [USERS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: async (body: OfficeUserForm) => {
      const response = await updateUserRequest(body)
      queryClient.refetchQueries({
        queryKey: [USERS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemoveUser = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      const response = await removeUserRequest(id)
      queryClient.refetchQueries({
        queryKey: [USERS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useGetBrands = () => {
  return usePaginatedQuery<Brand>({
    key: [BRANDS_QUERY_KEY],
    fetchFunction: async ({ limit, skip }) => {
      const query = convertToFeathersQuery({ limit, skip })
      return await getBrandsRequest(query)
    },
  })
}

export const useCreateBrand = () => {
  return useMutation({
    mutationFn: async (body: Brand) => {
      body.logo = await uploadLogoOrReturnLink(body.logo)
      const response = await createBrandRequest(body)
      queryClient.refetchQueries({
        queryKey: [BRANDS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useUpdateBrand = () => {
  return useMutation({
    mutationFn: async (body: Brand) => {
      body.logo = await uploadLogoOrReturnLink(body.logo)

      const response = await updateBrandRequest(body)
      queryClient.refetchQueries({
        queryKey: [BRANDS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemoveBrand = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      const response = await removeBrandRequest(id)
      queryClient.refetchQueries({
        queryKey: [BRANDS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useGetChannels = () => {
  return usePaginatedQuery<Channel>({
    key: [CHANNELS_QUERY_KEY],
    fetchFunction: async ({ limit, skip }) => {
      const query = convertToFeathersQuery({ limit, skip })
      return await getChannelRequest(query)
    },
  })
}

export const useCreateChannel = () => {
  return useMutation({
    mutationFn: async (body: Channel) => {
      body.logo = await uploadLogoOrReturnLink(body.logo)

      const response = await createChannelRequest(body)
      queryClient.refetchQueries({
        queryKey: [CHANNELS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useUpdateChannel = () => {
  return useMutation({
    mutationFn: async (body: Channel) => {
      body.logo = await uploadLogoOrReturnLink(body.logo)

      const response = await updateChannelRequest(body)
      queryClient.refetchQueries({
        queryKey: [CHANNELS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemoveChannel = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      await removeChannelRequest(Number(id))
      queryClient.refetchQueries({
        queryKey: [CHANNELS_QUERY_KEY],
      })
    },
  })
}

export const useUploadFileQuery = () => {
  return useMutation({
    mutationFn: async (data: UploadData) => {
      const uploadData = {
        ...data,
        key: uuidv4(),
      }
      await uploadFileRequest(uploadData)
    },
  })
}

export const useGetPlayers = (customQuery: CustomQuery) => {
  const queryKey = useStableQueryKey(customQuery)

  return usePaginatedQuery<Player>({
    key: [PLAYERS_QUERY_KEY, queryKey],
    fetchFunction: async ({ limit, skip }) => {
      const query = convertToFeathersQuery({ limit, skip, ...customQuery })
      return await getPlayersRequest(query)
    },
  })
}

export const useCreatePlayer = () => {
  return useMutation({
    mutationFn: async (body: Player) => {
      const response = await createPlayerRequest(body)
      queryClient.refetchQueries({
        queryKey: [PLAYERS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useUpdatePlayer = () => {
  return useMutation({
    mutationFn: async (body: Partial<Player>) => {
      const response = await updatePlayerRequest(body)
      queryClient.refetchQueries({
        queryKey: [PLAYERS_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemovePlayer = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      await removePlayerRequest(id)
      queryClient.refetchQueries({
        queryKey: [PLAYERS_QUERY_KEY],
      })
    },
  })
}

export const useGetBannedPlayers = () => {
  return usePaginatedQuery<BannedPlayer>({
    key: [BANNED_PLAYERS_QUERY_KEY],
    fetchFunction: async () => {
      return await getBannedPlayersRequest()
    },
  })
}

export const useToggleBanPlayer = () => {
  return useMutation({
    mutationFn: async (request: BanPlayerRequest) => {
      if (!isNilOrEmpty(request.ban_period)) {
        await banPlayerRequest(request)
      } else {
        await unbanPlayerRequest(request.player_id)
      }
      queryClient.refetchQueries({
        queryKey: [BANNED_PLAYERS_QUERY_KEY],
      })
      queryClient.refetchQueries({
        queryKey: [PLAYERS_QUERY_KEY],
      })
    },
  })
}

export const useGetProfanities = () => {
  return usePaginatedQuery<Profanity>({
    key: [PROFANITIES_QUERY_KEY],
    fetchFunction: async ({ limit, skip }) => {
      const query = convertToFeathersQuery({ limit, skip })
      return await getProfanitiesRequest(query)
    },
  })
}

export const useCreateProfanity = () => {
  return useMutation({
    mutationFn: async (body: Profanity) => {
      const response = await createProfanityRequest(body)
      queryClient.refetchQueries({
        queryKey: [PROFANITIES_QUERY_KEY],
      })
      return response
    },
  })
}

export const useUpdateProfanity = () => {
  return useMutation({
    mutationFn: async (body: Profanity) => {
      const response = await updateProfanityRequest(body)
      queryClient.refetchQueries({
        queryKey: [PROFANITIES_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemoveProfanity = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      await removeProfanityRequest(id)
      queryClient.refetchQueries({
        queryKey: [PROFANITIES_QUERY_KEY],
      })
    },
  })
}

export const useGetWhitelist = () => {
  return usePaginatedQuery<Whitelist>({
    key: [WHITELIST_QUERY_KEY],
    fetchFunction: async ({ limit, skip }) => {
      const query = convertToFeathersQuery({ limit, skip })
      return await getWhitelistRequest(query)
    },
  })
}

export const useCreateWhitelist = () => {
  return useMutation({
    mutationFn: async (body: Whitelist) => {
      const response = await createWhitelistRequest(body)
      queryClient.refetchQueries({
        queryKey: [WHITELIST_QUERY_KEY],
      })
      return response
    },
  })
}

export const useUpdateWhitelist = () => {
  return useMutation({
    mutationFn: async (body: Whitelist) => {
      const response = await updateWhitelistRequest(body)
      queryClient.refetchQueries({
        queryKey: [WHITELIST_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemoveWhitelist = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      await removeWhitelistRequest(id)
      queryClient.refetchQueries({
        queryKey: [WHITELIST_QUERY_KEY],
      })
    },
  })
}

export const useGetPinnedMessage = (brand_id: number, channel_id: number) => {
  return useQuery({
    queryKey: [PINNED_MESSAGE_QUERY_KEY, brand_id, channel_id],
    queryFn: () => getPinnedMessageRequest(brand_id, channel_id),
  })
}

export const useCreatePinMessage = () => {
  return useMutation({
    mutationFn: async (message: PinnedMessageBody) => {
      const response = await createPinnedMessageRequest(message)
      queryClient.invalidateQueries({
        queryKey: [PINNED_MESSAGE_QUERY_KEY],
      })
      return response
    },
  })
}

export const useRemovePinMessage = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      await removePinnedMessageRequest(id)
      queryClient.invalidateQueries({
        queryKey: [PINNED_MESSAGE_QUERY_KEY],
      })
    },
  })
}
