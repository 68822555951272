import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Button, IconButtonProps } from '@chakra-ui/react'
import React from 'react'
import { FaBan, FaUnlock } from 'react-icons/fa'
import { isNilOrEmpty } from 'utils/common'

export const EditButton: React.FC<Partial<IconButtonProps>> = ({ ...rest }) => {
  return (
    <Button variant='ghost' size='md' mr='2' color='blue.500' {...rest}>
      <EditIcon />
    </Button>
  )
}

export const RemoveButton: React.FC<Partial<IconButtonProps>> = ({ ...rest }) => {
  return (
    <Button variant='ghost' size='md' color='red.500' {...rest}>
      <DeleteIcon />
    </Button>
  )
}

interface BanButtonProps extends Partial<IconButtonProps> {
  bannedUntil?: string | null
}

export const BanButton: React.FC<BanButtonProps> = ({ bannedUntil, ...rest }) => {
  const isBanned = !isNilOrEmpty(bannedUntil)

  return (
    <Button
      variant='ghost'
      size='md'
      color={isBanned ? 'green.500' : 'red.500'}
      alignContent='center'
      justifyContent={'center'}
      {...rest}
    >
      {isBanned ? <FaBan /> : <FaUnlock />} {isBanned ? 'Unban' : 'Ban'}
    </Button>
  )
}
