import deepEqual from 'deep-equal'
import { useEffect, useRef, useState } from 'react'

type QueryParams = Record<string, any>

export function useStableQueryKey(params: QueryParams) {
  const [queryKey, setQueryKey] = useState('')
  const prevParamsRef = useRef(params)

  useEffect(() => {
    // Check if the current params are deeply equal to the previous params using deep-equal
    if (!deepEqual(prevParamsRef.current, params, { strict: true })) {
      // Update the query key only if params have changed
      setQueryKey(JSON.stringify(params))
      prevParamsRef.current = params // Update the ref to the current params
    }
  }, [params]) // Depend on `params` to re-run this effect when params object changes

  return queryKey
}
