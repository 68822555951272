import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { Action, configureStore } from '@reduxjs/toolkit'
import { injectStore } from 'api/ApiService'
import { persistStore } from 'redux-persist'
import { ThunkDispatch } from 'redux-thunk'
import { authMiddleware } from './auth/middleware'
import rootReducer from './rootReducer'

export const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(authMiddleware),
  devTools: false,
})
injectStore(reduxStore)

export const reduxPersistor = persistStore(reduxStore)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppRootState = ReturnType<typeof reduxStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch & ThunkDispatch<AppRootState, unknown, Action>

// app/hooks.ts

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector

export default reduxStore
