import { Box, Button, Flex } from '@chakra-ui/react'
import { useGetBrands } from 'api/queries'

import GenericTable from 'components/genericTable'
import { EditButton, RemoveButton } from 'components/genericTable/ActionButtons'
import ListLayout from 'components/list/ListLayout'
import React from 'react'
import { Brand } from 'types/types'
import { BrandFormModal } from './components/BrandFormModal'
import { BrandRemoveDialog } from './components/BrandRemoveDialog'
import { useBrandsTable } from './hooks/useBrandsTable'

const BrandsView = () => {
  const { data } = useGetBrands()
  const { actionState, columns } = useBrandsTable()
  const { setMode, actionMode, actionData, hideCurrentMode, hideMode } = actionState

  const renderActionColumn = (data: Brand) => {
    return (
      <Flex justify='flex-start' align='center'>
        <EditButton onClick={() => setMode('edit', data)} />
        <RemoveButton onClick={() => setMode('remove', data)} />
      </Flex>
    )
  }

  return (
    <ListLayout>
      <Box w='fit-content'>
        <Button colorScheme='blue' onClick={() => setMode('create')}>
          Create Brand
        </Button>
      </Box>
      <GenericTable
        columns={columns}
        data={data}
        renderActionColumn={renderActionColumn}
        title={'Brands'}
      />
      <BrandFormModal
        mode={actionMode}
        isOpen={actionMode === 'create' || actionMode === 'edit'}
        onClose={hideCurrentMode}
        data={actionData}
      />
      <BrandRemoveDialog
        isOpen={actionState.remove}
        onClose={() => hideMode('remove')}
        id={actionState.actionData?.id}
      />
    </ListLayout>
  )
}

export default BrandsView
