import { TableColumn } from 'components/genericTable/types'
import { useActionState } from 'hooks/useActionState'

import { Channel } from 'types/types'

export const useChannelsTable = () => {
  const actionState = useActionState<Channel>()

  const columns: TableColumn[] = [
    { id: 'id', Header: 'ID', accessor: 'id' },
    { id: 'name', Header: 'Name', accessor: 'name' },
    { id: 'actions', Header: 'Actions', accessor: 'actions' },
  ]

  return {
    actionState,
    columns,
  }
}
