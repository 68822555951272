import { isEmpty, isNil } from 'lodash'
import moment from 'moment'

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })

export const formatDate = (date: string, format: string = 'MMM D, YYYY hh:mm A'): string => {
  return moment(date).format(format)
}
export const isNilOrEmpty = <T>(obj: T): boolean => {
  return isNil(obj) || isEmpty(obj)
}
