import { Dispatch, Middleware } from 'redux'
import { CustomAction } from 'reduxStore/types'
import { unauthorizedAction } from './common'
import { logout } from './slice'

export const authMiddleware: Middleware<{}, any, Dispatch<CustomAction>> =
  (store) => (next) => (action) => {
    if ((action as CustomAction).type === unauthorizedAction.type) {
      store.dispatch(logout())
    }
    return next(action)
  }
