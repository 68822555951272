import { TableColumn } from 'components/genericTable/types'
import { useActionState } from 'hooks/useActionState'

import { Profanity } from 'types/types'

export const useProfanitiesTable = () => {
  const actionState = useActionState<Profanity>()

  const columns: TableColumn[] = [
    { id: 'id', Header: 'ID', accessor: 'id' },
    { id: 'word', Header: 'Word', accessor: 'word' },
    { id: 'actions', Header: 'Action', accessor: 'action' },
  ]

  return {
    actionState,
    columns,
  }
}
