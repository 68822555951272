import { TableColumn } from 'components/genericTable/types'
import { useActionState } from 'hooks/useActionState'

import { Player } from 'types/types'

export const usePlayersTable = () => {
  const actionState = useActionState<Player>()

  const columns: TableColumn[] = [
    { id: 'id', Header: 'ID', accessor: 'id' },
    { id: 'username', Header: 'Username', accessor: 'username' },
    { id: 'role', Header: 'Role', accessor: 'role' },
    { id: 'email', Header: 'Email', accessor: 'email' },
    { id: 'firstname', Header: 'First Name', accessor: 'firstname' },
    { id: 'lastname', Header: 'Last Name', accessor: 'lastname' },
    { id: 'status', Header: 'Status', accessor: 'status' },
    { id: 'actions', Header: 'Actions', accessor: 'actions' },
  ]

  return {
    actionState,
    columns,
  }
}
