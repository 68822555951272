import { useToast } from '@chakra-ui/react'
import { useCreateBrand, useUpdateBrand } from 'api/queries'
import { DynamicFormField } from 'components/form/types'
import { ImageUpload } from 'components/imageUpload/ImageUpload'
import { FormikProps } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import { Brand } from 'types/types'

export interface UseBrandsModalProps {
  mode: TableActionMode
  onClose: () => void
  data?: Brand
  formik: FormikProps<Brand>
}

export const useBrandsModal = ({ mode, onClose, data, formik }: UseBrandsModalProps) => {
  const toast = useToast()
  const createQuery = useCreateBrand()
  const updateQuery = useUpdateBrand()

  const mutationQuery = mode === 'edit' ? updateQuery : createQuery

  const handleOnSubmit = async (values: Brand) => {
    try {
      await mutationQuery.mutateAsync({
        ...data,
        ...values,
        min_wager_amount: Number(values.min_wager_amount),
      })
      toast({
        title: mode === 'edit' ? 'Brand is updated.' : 'Brand is created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const formFields: DynamicFormField[] = [
    { id: 'name', name: 'Brand Name', type: 'input' },
    { id: 'min_wager_amount', name: 'Min Wager Amount', type: 'input' },
    { id: 'min_wager_currency', name: 'Min Wager Currency', type: 'input' },
    {
      id: 'logo',
      name: 'Brand Logo',
      type: 'custom',
      customComponent: () => (
        <ImageUpload
          value={formik.values.logo}
          onChange={(value: string) => formik.setFieldValue('logo', value)}
        />
      ),
    },
  ]

  return {
    handleOnSubmit,
    isLoading: mutationQuery.isPending,
    formFields,
  }
}
