import { Box, Button, Flex } from '@chakra-ui/react'
import { useGetChannels } from 'api/queries'

import GenericTable from 'components/genericTable'
import { EditButton, RemoveButton } from 'components/genericTable/ActionButtons'
import ListLayout from 'components/list/ListLayout'
import React from 'react'
import { Channel } from 'types/types'
import { ChannelFormModal } from './components/ChannelFormModal'
import { ChannelRemoveDialog } from './components/ChannelRemoveDialog'
import { useChannelsTable } from './hooks/useChannelsTable'

export const ChannelsView = () => {
  const { data } = useGetChannels()
  const { actionState, columns } = useChannelsTable()
  const { setMode, actionMode, actionData, hideCurrentMode, hideMode } = actionState

  const renderActionColumn = (data: Channel) => {
    return (
      <Flex justify='flex-start' align='center'>
        <EditButton onClick={() => setMode('edit', data)} />
        <RemoveButton onClick={() => setMode('remove', data)} />
      </Flex>
    )
  }

  return (
    <ListLayout>
      <Box w='fit-content'>
        <Button colorScheme='blue' onClick={() => setMode('create')}>
          Create Channel
        </Button>
      </Box>
      <GenericTable
        columns={columns}
        data={data}
        renderActionColumn={renderActionColumn}
        title={'Channels'}
      />
      <ChannelFormModal
        mode={actionMode}
        isOpen={actionMode === 'create' || actionMode === 'edit'}
        onClose={hideCurrentMode}
        data={actionData}
      />
      <ChannelRemoveDialog
        isOpen={actionState.remove}
        onClose={() => hideMode('remove')}
        id={actionState.actionData?.id}
      />
    </ListLayout>
  )
}

export default ChannelsView
