import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import React from 'react'

import { createRoot } from 'react-dom/client'
import './assets/css/App.css'

import App from 'App'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
