import { Alert, AlertIcon, Flex, IconButton, Text } from '@chakra-ui/react'
import { AiFillDelete } from 'react-icons/ai'
import { PinnedMessage } from 'types/types'

interface CurrentPinnedMessageProps {
  message: PinnedMessage | null
  onRemove: () => void
  isLoading: boolean
}

export const CurrentPinnedMessage = ({
  message,
  onRemove,
  isLoading,
}: CurrentPinnedMessageProps) => {
  if (isLoading) {
    return <Text>Loading...</Text>
  }

  if (!message?.message) {
    return <Text color='gray.500'>No message pinned</Text>
  }

  return (
    <Alert status='info' borderRadius='md'>
      <AlertIcon />
      <Flex justify='space-between' w='100%' align='center'>
        <Text>{message.message}</Text>
        <IconButton
          icon={<AiFillDelete />}
          onClick={onRemove}
          aria-label='Remove pin'
          size='sm'
          colorScheme='red'
          variant='ghost'
        />
      </Flex>
    </Alert>
  )
}
