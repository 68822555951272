import { Box, Button, Flex } from '@chakra-ui/react'
import { useGetProfanities } from 'api/queries'
import GenericTable from 'components/genericTable'
import { EditButton, RemoveButton } from 'components/genericTable/ActionButtons'
import ListLayout from 'components/list/ListLayout'
import { SearchBar } from 'components/navbar/searchBar/SearchBar'
import useSearchState from 'hooks/useSearchState'
import React from 'react'
import { Profanity } from 'types/types'
import { ProfanitiesFormModal } from './components/ProfanitiesFormModal'
import { ProfanitiesRemoveDialog } from './components/ProfanitiesRemoveDialog'
import { useProfanitiesTable } from './hooks/useProfanitiesTable'

const ProfanitiesView = () => {
  const { actionState, columns } = useProfanitiesTable()
  const { setMode, actionMode, actionData, hideCurrentMode, hideMode } = actionState
  const { onSearchTextChange, searchText } = useSearchState()

  const { data, isFetching, fetchNextPage, metadata } = useGetProfanities()

  const renderActionColumn = (data: Profanity) => {
    return (
      <Flex justify='flex-start' align='center'>
        <EditButton onClick={() => setMode('edit', data)} />
        <RemoveButton onClick={() => setMode('remove', data)} />
      </Flex>
    )
  }

  return (
    <ListLayout>
      <SearchBar searchValue={searchText} onSearchChange={onSearchTextChange} />
      <Box w='fit-content'>
        <Button colorScheme='blue' onClick={() => setMode('create')}>
          Create Profanity
        </Button>
      </Box>
      <GenericTable
        columns={columns}
        data={data}
        renderActionColumn={renderActionColumn}
        title={'Profanities'}
        hasMore={data.length < metadata.total}
        isLoading={isFetching}
        fetchMoreData={fetchNextPage}
      />
      <ProfanitiesFormModal
        mode={actionMode}
        isOpen={actionMode === 'create' || actionMode === 'edit'}
        onClose={hideCurrentMode}
        data={actionData}
      />
      <ProfanitiesRemoveDialog
        isOpen={actionState.remove}
        onClose={() => hideMode('remove')}
        id={actionState.actionData?.id}
      />
    </ListLayout>
  )
}

export default ProfanitiesView
