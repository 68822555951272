import DynamicForm from 'components/form'
import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import React from 'react'
import { Channel } from 'types/types'
import { channelsValidationSchema } from '../constants'
import { useChannelsModal } from '../hooks/useChannelsModal'

interface ChannelFormModalProps {
  isOpen: boolean
  onClose: () => void
  data?: Channel
  mode: TableActionMode
}

export const ChannelFormModal = ({ isOpen, onClose, data, mode }: ChannelFormModalProps) => {
  const formik = useFormik<Channel>({
    initialValues: {
      ...data,
    },
    validationSchema: channelsValidationSchema,
    onSubmit: (values) => handleOnSubmit(values),
    enableReinitialize: true,
  })

  const { handleOnSubmit, isLoading, formFields } = useChannelsModal({
    data,
    mode,
    onClose,
    formik,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={handleClose}
      title={mode === 'edit' ? 'Edit Channel' : 'Create Channel'}
      onOk={formik.handleSubmit}
      onCancel={handleClose}
      isLoading={isLoading}
      okText={mode === 'edit' ? 'Save Changes' : 'Create'}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
