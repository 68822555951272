import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Brand, OfficeUser } from 'types/types'

export const initialState: AuthState = {
  user: null,
  brand: null,
}

export interface AuthState {
  user: OfficeUser | null
  brand: Brand | null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<OfficeUser>) => {
      state.user = action.payload
    },
    setBrand: (state, action: PayloadAction<Brand>) => {
      state.brand = action.payload
    },
    logout: (state) => {
      state.user = null
    },
  },
})

export const { setUser, logout, setBrand } = authSlice.actions

export default authSlice.reducer
