export const AppPaths = {
  Admin: '/admin',
  Auth: '/auth',
  Default: '/default',
  Profile: '/profile',
  Users: '/users',
  Brands: '/brands',
  Chat: '/chat',
  Channels: '/channels',
  Players: '/players',
  BannedPlayers: '/banned-players',
  Profanities: '/profanities',
  Whitelist: '/whitelist',
  PinnedMessage: '/pinned-message',
}

export const AUTH_DEFAULT_PATH = AppPaths.Admin + AppPaths.Players
