import { Flex } from '@chakra-ui/react'
import { useGetBrands } from 'api/queries'
import { BrandSelector } from 'components/selectors/BrandSelector'

import { HSeparator } from 'components/separator/Separator'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBrand } from 'reduxStore/auth/selectors'
import { setBrand } from 'reduxStore/auth/slice'

export function SidebarBrand() {
  const dispatch = useDispatch()
  const { data = [] } = useGetBrands()
  const brand = useSelector(selectBrand)

  const handleSelect = (id: string) => {
    const item = data.find((item) => item.id?.toString() === id)
    dispatch(setBrand(item))
  }

  useEffect(() => {
    if (data.length > 0) {
      const currentBrand = data.find((item) => item.id === brand?.id)
      const newBrand = currentBrand || data[0]
      dispatch(setBrand(newBrand))
    } else {
      dispatch(setBrand(null))
    }
  }, [data])

  return (
    <Flex alignItems='flex-start' marginBottom={5} flexDirection={'column'}>
      <Flex alignSelf='start' justifyContent={'center'} marginLeft={4}>
        <BrandSelector data={data} brand={brand} handleSelect={handleSelect} />
      </Flex>
      <HSeparator marginTop={5} />
    </Flex>
  )
}

export default SidebarBrand
