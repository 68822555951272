import { FilterOption } from 'components/filters/types'

import useFiltersState from 'hooks/useFiltersState'
import moment from 'moment'
import { useMemo } from 'react'

import { PlayerFilter, PlayersFilterState } from 'types/types'

const initialFilterState: PlayersFilterState = {
  boolFields: [],
}

const defaultFilterState: PlayersFilterState = {
  boolFields: [PlayerFilter.Banned_Until, PlayerFilter.Role],
}

const filterItems: FilterOption[] = [
  {
    id: PlayerFilter.Banned_Until,
    label: 'Banned',
  },
  {
    id: PlayerFilter.Role,
    label: 'Moderator',
  },
]

const mapFiltersToQuery = (filterState: PlayersFilterState) => {
  const isUserBanned = filterState.boolFields.includes(PlayerFilter.Banned_Until)
  const isUserModerator = filterState.boolFields.includes(PlayerFilter.Role)

  return {
    [PlayerFilter.Banned_Until]: isUserBanned
      ? { $gt: moment().subtract(1, 'd').toISOString() }
      : undefined,
    [PlayerFilter.Role]: isUserModerator ? { $eq: 'player_moderator' } : undefined,
  }
}

const useFilters = () => {
  const { filterState, updateFilter } = useFiltersState<PlayersFilterState>(
    initialFilterState,
    defaultFilterState
  )

  const filtersQuery = useMemo(() => mapFiltersToQuery(filterState), [filterState.boolFields])

  return {
    filterState,
    updateFilter,
    filterItems,
    filtersQuery,
  }
}

export default useFilters
