import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

export const REQUEST_TIMEOUT = 30 * 1000

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
} as AxiosRequestHeaders

export const baseURL = process.env.REACT_APP_API_URL

export const apiConfig = {
  headers: {
    ...defaultHeaders,
  },
  timeout: REQUEST_TIMEOUT,
  baseURL: baseURL,
} as AxiosRequestConfig

export const BackOfficeEndpoint = {
  login: '/authentication',
  users: '/users',
  brands: '/brands',
  channels: '/channels',
  s3Upload: '/s3-upload',
  players: '/players',
  banHistory: '/players-ban-history',
  banPlayer: '/players/ban',
  unbanPlayer: '/players/unban',
  profanities: '/profanities',
  webSocket: baseURL,
  baseUrl: baseURL,
  whitelist: '/whitelist',
  pinnedMessage: '/pinned-message',
}

export const UNAUTHORIZED_STATUS = 401

export const USERS_QUERY_KEY = 'users'

export const BRANDS_QUERY_KEY = 'brands'

export const CHANNELS_QUERY_KEY = 'channels'

export const PLAYERS_QUERY_KEY = 'players'

export const BANNED_PLAYERS_QUERY_KEY = 'bannedPlayers'

export const PROFANITIES_QUERY_KEY = 'profanities'

export const WHITELIST_QUERY_KEY = 'whitelist'

export const PINNED_MESSAGE_QUERY_KEY = 'pinnedMessage'
