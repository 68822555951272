import pica from 'pica'

/**
 * Converts an image file to PNG if it's not already a PNG. Leaves PNG files unchanged.
 * @param imageFile The image file to potentially convert.
 * @returns A promise that resolves with either the original PNG file or a converted PNG file.
 */
export const ensurePngFormat = (imageFile: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    // If the file is already a PNG, return it directly
    if (imageFile.type === 'image/png') {
      resolve(imageFile)
      return
    }

    // For non-PNG images, convert to PNG
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = async () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        if (!ctx) {
          reject(new Error('Failed to get canvas context'))
          return
        }

        ctx.drawImage(img, 0, 0)

        try {
          // Convert canvas content to a PNG Blob
          const blob: Blob | null = await pica().toBlob(canvas, 'image/png')

          if (blob) {
            // Create a File object from the Blob
            const pngFile = new File([blob], imageFile.name.replace(/\.[^/.]+$/, '') + '.png', {
              type: 'image/png',
            })

            resolve(pngFile)
          } else {
            reject(new Error('Failed to convert canvas to PNG blob'))
          }
        } catch (error) {
          reject(error)
        }
      }
      img.onerror = () => reject(new Error('Image loading error'))
      if (e.target?.result) {
        img.src = e.target.result.toString()
      } else {
        reject(new Error('FileReader did not provide a result'))
      }
    }
    reader.onerror = () => reject(new Error('FileReader error'))
    reader.readAsDataURL(imageFile)
  })
}
