import { TableColumn } from 'components/genericTable/types'
import { useActionState } from 'hooks/useActionState'

import { Whitelist } from 'types/types'

export const useWhitelistTable = () => {
  const actionState = useActionState<Whitelist>()

  const columns: TableColumn[] = [
    { id: 'id', Header: 'ID', accessor: 'id' },
    { id: 'link', Header: 'Link', accessor: 'link' },
    { id: 'actions', Header: 'Action', accessor: 'action' },
  ]

  return {
    actionState,
    columns,
  }
}
