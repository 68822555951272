import { Box, useColorModeValue } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

interface AuthProps extends PropsWithChildren {}

const AuthLayout = ({ children }: AuthProps) => {
  const authBg = useColorModeValue('white', 'navy.900')

  return (
    <Box display='flex' alignItems='center' justifyContent='center' minHeight='100vh'>
      <Box
        bg={authBg}
        w='100%'
        p='4'
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease'
      >
        {children}
      </Box>
    </Box>
  )
}

export default AuthLayout
