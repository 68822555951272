import DynamicForm from 'components/form'
import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import React from 'react'
import { Brand } from 'types/types'
import { brandsValidationSchema } from '../constants'
import { useBrandsModal } from '../hooks/useBrandsModal'

interface BrandFormModalProps {
  isOpen: boolean
  onClose: () => void
  data?: Brand
  mode: TableActionMode
}

export const BrandFormModal = ({ isOpen, onClose, data, mode }: BrandFormModalProps) => {
  const formik = useFormik({
    initialValues: {
      ...data,
      min_wager_currency: 'EUR',
    },
    validationSchema: brandsValidationSchema,
    onSubmit: (values) => handleOnSubmit(values),
    enableReinitialize: true,
  })

  const { handleOnSubmit, isLoading, formFields } = useBrandsModal({ data, mode, onClose, formik })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={handleClose}
      title={mode === 'edit' ? 'Edit Brand' : 'Create Brand'}
      onOk={formik.handleSubmit}
      onCancel={handleClose}
      isLoading={isLoading}
      okText={mode === 'edit' ? 'Save Changes' : 'Create'}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
