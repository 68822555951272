import { feathersApp } from 'api/endpoints'
import { AppPaths } from 'components/routes/constants'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { setUser } from 'reduxStore/auth/slice'

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authData = await feathersApp.authentication.reAuthenticate(true)
        dispatch(setUser(authData.user))
        setIsAuthenticated(true)
      } catch (error) {
        console.error('Authentication failed:', error)
        setIsAuthenticated(false)
      }
    }

    checkAuth()
  }, [])

  if (isAuthenticated === null) {
    // Authentication check is in progress
    return <div>Loading...</div> // Or any loading indicator
  }

  if (isAuthenticated === false) {
    return <Navigate to={AppPaths.Auth} state={{ from: location }} replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
