import { WarningIcon } from '@chakra-ui/icons' // This is a generic warning icon. Feel free to replace it with any other appropriate icon.
import { Box, Flex, Icon, List, ListItem, Text } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import React from 'react'

interface FormErrorsDisplayProps<T> {
  errors: FormikErrors<T>
}

const FormErrorsDisplay = <T extends Record<string, any>>({
  errors,
}: FormErrorsDisplayProps<T>) => {
  const errorEntries = Object.entries(errors)

  if (errorEntries.length === 0) {
    return null
  }

  return (
    <Box my={2} p={2}>
      <Text fontSize='md' mb={2} fontWeight='medium' color='red.400'>
        Please fix the following errors
      </Text>
      <List spacing={2}>
        {errorEntries.map(([field, message]) => (
          <Flex key={field} align='center'>
            <Icon as={WarningIcon} color='red.400' mr={2} />
            <ListItem fontSize='sm' color='red.400'>
              {message}
            </ListItem>
          </Flex>
        ))}
      </List>
    </Box>
  )
}

export default FormErrorsDisplay
