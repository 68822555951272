import { useToast } from '@chakra-ui/react'
import { useToggleBanPlayer } from 'api/queries'
import DynamicForm from 'components/form'
import { FormFields } from 'components/form/types'
import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { BanPeriod } from 'types/types'
import { BAN_OPTIONS } from './constants'

interface BanPlayerModalProps {
  isOpen: boolean
  onClose: () => void
  playerId: number
}

export const BanPlayerModal = ({ isOpen, onClose, playerId }: BanPlayerModalProps) => {
  const toast = useToast()
  const { mutateAsync } = useToggleBanPlayer()

  const handleOnSubmit = async (banPeriod: BanPeriod) => {
    try {
      await mutateAsync({
        player_id: playerId,
        ban_period: banPeriod,
      })
      toast({
        title: banPeriod ? 'Player is banned.' : 'Player is unbanned.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      banPeriod: BanPeriod.OneHour,
    },
    onSubmit: (values) => {
      handleOnSubmit(values.banPeriod as BanPeriod)
    },
    enableReinitialize: true,
  })

  const formFields = [
    {
      id: 'banPeriod',
      name: 'Ban Period',
      type: 'select',
      options: BAN_OPTIONS,
    },
  ] as FormFields

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={'Ban options'}
      onOk={formik.handleSubmit}
      onCancel={onClose}
      isLoading={false}
      okText={'Update'}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
