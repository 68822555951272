import DynamicForm from 'components/form'

import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import React from 'react'
import { OfficeUser, UserRoles } from 'types/types'
import { userValidationSchema } from '../constants'
import { useUsersModal } from '../hooks/useUsersModal'

interface UserFormModalProps {
  isOpen: boolean
  onClose: () => void
  data?: OfficeUser
  mode: TableActionMode
}

export const UserFormModal: React.FC<UserFormModalProps> = ({ isOpen, onClose, data, mode }) => {
  const { handleOnSubmit, isLoading, formFields } = useUsersModal({ data, mode, onClose })

  const formik = useFormik<OfficeUser>({
    initialValues: {
      ...data,
      role: data?.role ?? UserRoles.Support,
    },
    validationSchema: userValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => handleOnSubmit(values),
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={handleClose}
      title={mode === 'edit' ? 'Edit User' : 'Create User'}
      onOk={() => formik.handleSubmit()}
      onCancel={handleClose}
      okText={mode === 'edit' ? 'Save Changes' : 'Create'}
      isLoading={isLoading}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
