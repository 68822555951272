import { useRef } from 'react'

import { debounce, DebouncedFunc } from 'lodash'

const useDebouncedRef = <T extends (...args: any[]) => any>(
  callback: T,
  delay: number
): DebouncedFunc<any> => {
  const debouncedCallback = useRef(debounce(callback, delay))
  return debouncedCallback.current
}

export default useDebouncedRef
