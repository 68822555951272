import { useToast } from '@chakra-ui/react'
import {
  useCreateProfanity,
  useCreateWhitelist,
  useUpdateProfanity,
  useUpdateWhitelist,
} from 'api/queries'
import { DynamicFormField } from 'components/form/types'
import { FormikProps } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import { Whitelist } from 'types/types'

export interface UseWhitelistModalProps {
  mode: TableActionMode
  onClose: () => void
  data?: Whitelist
  formik: FormikProps<Whitelist>
}

export const useWhitelistModal = ({ mode, onClose, data }: UseWhitelistModalProps) => {
  const toast = useToast()
  const createQuery = useCreateWhitelist()
  const updateQuery = useUpdateWhitelist()

  const mutationQuery = mode === 'edit' ? updateQuery : createQuery

  const handleOnSubmit = async (values: Whitelist) => {
    try {
      await mutationQuery.mutateAsync({
        ...data,
        ...values,
      })
      toast({
        title: mode === 'edit' ? 'Whitelist link is updated.' : 'Whitelist link is created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const formFields: DynamicFormField[] = [{ id: 'link', name: 'Link', type: 'input' }]

  return {
    handleOnSubmit,
    isLoading: mutationQuery.isPending,
    formFields,
  }
}
