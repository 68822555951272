import { Box, Checkbox, CheckboxGroup, Stack, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

import { FilterOption, FilterOptionsProps } from './types'
import { toggleFilter } from './utils'

const FilterOptions: FC<FilterOptionsProps> = ({
  selectedFilterIds,
  filterOptions,
  onFilterChange,
}) => {
  const onCheckboxChange = (filterId: string) => {
    const updatedFilterIds = toggleFilter(filterId, selectedFilterIds)

    onFilterChange(updatedFilterIds, filterId)
  }

  const renderCheckBox = (option: FilterOption) => {
    const { id, label, description } = option

    return (
      <Checkbox
        key={id}
        isChecked={selectedFilterIds.includes(id)}
        onChange={() => onCheckboxChange(id)}
        spacing={2}
        data-testid={`FilterOptions.CheckBox.${id}`}
        borderColor='gray.300'
      >
        <Box>
          <Text fontWeight='bold' fontSize='md' color={'blackAlpha.700'}>
            {label}
          </Text>
          {description && <Text fontSize='sm'>{description}</Text>}
        </Box>
      </Checkbox>
    )
  }

  const renderFilterOptions = () => (
    <CheckboxGroup>
      <Stack spacing={4}>{filterOptions.map(renderCheckBox)}</Stack>
    </CheckboxGroup>
  )

  return <Box>{renderFilterOptions()}</Box>
}

export default FilterOptions
