import DynamicForm from 'components/form'
import { ActionModal } from 'components/modal/ActionModal'
import { useFormik } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import React from 'react'
import { Whitelist } from 'types/types'
import { whitelistValidationSchema } from '../constants'
import { useWhitelistModal } from '../hooks/useWhitelistModal'

interface WhitelistFormModalProps {
  isOpen: boolean
  onClose: () => void
  data?: Whitelist
  mode: TableActionMode
}

export const WhitelistFormModal = ({ isOpen, onClose, data, mode }: WhitelistFormModalProps) => {
  const formik = useFormik({
    initialValues: {
      ...data,
      link: data?.link || '',
    },
    validationSchema: whitelistValidationSchema,
    onSubmit: (values) => handleOnSubmit(values),
    enableReinitialize: true,
  })

  const { handleOnSubmit, isLoading, formFields } = useWhitelistModal({
    data,
    mode,
    onClose,
    formik,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={handleClose}
      title={mode === 'edit' ? 'Edit whitelist link' : 'Create whitelist link'}
      onOk={formik.handleSubmit}
      onCancel={handleClose}
      isLoading={isLoading}
      okText={mode === 'edit' ? 'Save Changes' : 'Create'}
    >
      <DynamicForm formik={formik} formFields={formFields} />
    </ActionModal>
  )
}
