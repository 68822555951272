import {
  BannedPlayer,
  BanPlayerRequest,
  Brand,
  Channel,
  LoginPayload,
  OfficeUser,
  OfficeUserForm,
  PinnedMessage,
  PinnedMessageBody,
  Player,
  Profanity,
  UploadData,
  UploadResponse,
  Whitelist,
} from 'types/types'
import ApiService from './ApiService'
import { PaginatedData } from './types'
import { CustomQuery } from './utils'

import authentication, { AuthenticationClient } from '@feathersjs/authentication-client'
import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import { SPINBET_AUTH_TOKEN } from 'constants/common'
import { omit } from 'lodash'
import { BackOfficeEndpoint } from './constants'

export const feathersApp = feathers()
export const restClient = rest(BackOfficeEndpoint.baseUrl)
feathersApp.configure(restClient.axios(ApiService.service))
feathersApp.configure(
  authentication({
    storage: window.localStorage,
    storageKey: SPINBET_AUTH_TOKEN,
  })
)

export const loginRequest = async (
  body: LoginPayload
): ReturnType<AuthenticationClient['authenticate']> => {
  return feathersApp.authenticate({
    strategy: 'local',
    email: body.email,
    password: body.password,
  })
}

export const getUsersRequest = async (
  query: CustomQuery = {}
): Promise<PaginatedData<OfficeUser>> => {
  return feathersApp.service(BackOfficeEndpoint.users).find({ query })
}

export const createUserRequest = async (body: OfficeUserForm): Promise<OfficeUser> => {
  return feathersApp.service(BackOfficeEndpoint.users).create({
    ...body,
    password: 'password',
  })
}

export const updateUserRequest = async (user: OfficeUser): Promise<OfficeUser> => {
  return feathersApp.service(BackOfficeEndpoint.users).patch(user.id, user)
}

export const removeUserRequest = async (id: number): Promise<OfficeUser> => {
  return feathersApp.service(BackOfficeEndpoint.users).remove(id)
}

export const getBrandsRequest = async (query: CustomQuery = {}): Promise<PaginatedData<Brand>> => {
  return feathersApp.service(BackOfficeEndpoint.brands).find({ query })
}

export const createBrandRequest = async (body: Brand): Promise<Brand> => {
  return feathersApp.service(BackOfficeEndpoint.brands).create(body)
}

export const updateBrandRequest = async (brand: Brand): Promise<Brand> => {
  return feathersApp.service(BackOfficeEndpoint.brands).patch(brand.id, brand)
}

export const removeBrandRequest = async (id: number): Promise<OfficeUser> => {
  return feathersApp.service(BackOfficeEndpoint.brands).remove(id)
}

export const getChannelRequest = async (
  query: CustomQuery = {}
): Promise<PaginatedData<Channel>> => {
  return feathersApp.service(BackOfficeEndpoint.channels).find({ query })
}

export const createChannelRequest = async (channel: Channel): Promise<Channel> => {
  return feathersApp.service(BackOfficeEndpoint.channels).create({
    ...channel,
    brand_id: Number(channel.brand_id),
  })
}

export const updateChannelRequest = async (channel: Channel): Promise<Channel> => {
  return feathersApp.service(BackOfficeEndpoint.channels).patch(channel.id, channel)
}

export const removeChannelRequest = async (id: number): Promise<void> => {
  console.log({ id })
  await feathersApp.service(BackOfficeEndpoint.channels).remove(id)
}

export const uploadFileRequest = async (uploadData: UploadData): Promise<UploadResponse> => {
  return feathersApp.service(BackOfficeEndpoint.s3Upload).create(uploadData)
}

export const getPlayersRequest = async (
  query: CustomQuery = {}
): Promise<PaginatedData<Player>> => {
  return feathersApp.service(BackOfficeEndpoint.players).find({ query })
}

export const createPlayerRequest = async (body: Player): Promise<Player> => {
  return feathersApp.service(BackOfficeEndpoint.players).create(body)
}

export const updatePlayerRequest = async (player: Partial<Player>): Promise<Player> => {
  return feathersApp.service(BackOfficeEndpoint.players).patch(player.id, player)
}

export const removePlayerRequest = async (id: number): Promise<void> => {
  await feathersApp.service(BackOfficeEndpoint.players).remove(id)
}

export const getBannedPlayersRequest = async (
  query: CustomQuery = {}
): Promise<PaginatedData<BannedPlayer>> => {
  return feathersApp.service(BackOfficeEndpoint.banHistory).find({ query })
}

export const banPlayerRequest = async (body: BanPlayerRequest): Promise<BannedPlayer> => {
  return feathersApp.service(BackOfficeEndpoint.banPlayer).create(body)
}

export const unbanPlayerRequest = async (player_id: number): Promise<void> => {
  await feathersApp.service(BackOfficeEndpoint.unbanPlayer).create({
    player_id,
  })
}

export const getProfanitiesRequest = async (
  query: CustomQuery = {}
): Promise<PaginatedData<Profanity>> => {
  return feathersApp.service(BackOfficeEndpoint.profanities).find({ query })
}

export const createProfanityRequest = async (body: Profanity): Promise<Profanity> => {
  return feathersApp.service(BackOfficeEndpoint.profanities).create(body)
}

export const updateProfanityRequest = async (profanity: Profanity): Promise<Profanity> => {
  return feathersApp.service(BackOfficeEndpoint.profanities).patch(profanity.id, profanity)
}

export const removeProfanityRequest = async (id: number): Promise<void> => {
  await feathersApp.service(BackOfficeEndpoint.profanities).remove(id)
}

export const getWhitelistRequest = async (
  query: CustomQuery = {}
): Promise<PaginatedData<Whitelist>> => {
  return feathersApp.service(BackOfficeEndpoint.whitelist).find({ query })
}

export const createWhitelistRequest = async (body: Whitelist): Promise<Whitelist> => {
  return feathersApp
    .service(BackOfficeEndpoint.whitelist)
    .create(omit(body, 'id', 'created_at', 'updated_at'))
}

export const updateWhitelistRequest = async (whitelist: Whitelist): Promise<Whitelist> => {
  return feathersApp.service(BackOfficeEndpoint.whitelist).patch(whitelist.id, whitelist)
}

export const removeWhitelistRequest = async (id: number): Promise<void> => {
  await feathersApp.service(BackOfficeEndpoint.whitelist).remove(id)
}
export const getPinnedMessageRequest = async (
  brand_id: number,
  channel_id: number
): Promise<PinnedMessage> => {
  const response = await feathersApp.service(BackOfficeEndpoint.pinnedMessage).find({
    query: {
      channel_id: Number(channel_id),
      brand_id: Number(brand_id),
    },
  })

  return response?.[0] ?? ''
}

export const createPinnedMessageRequest = async (
  message: PinnedMessageBody
): Promise<PinnedMessage> => {
  const response = await feathersApp.service(BackOfficeEndpoint.pinnedMessage).create(message)
  return response
}

export const removePinnedMessageRequest = async (id: number): Promise<void> => {
  const response = await feathersApp.service(BackOfficeEndpoint.pinnedMessage).remove(id)
  return response
}
