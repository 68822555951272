import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import authSlice from './auth/slice'
import { rootPersistConfig } from './persistConfig'
import { RootState } from './types'

const rootReducer = combineReducers({
  auth: authSlice,
})

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer)

export default persistedRootReducer

export const selectRootState = (state: RootState): RootState => state
