import { Box, SimpleGrid } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

const ListLayout = ({ children }: PropsWithChildren) => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        {children}
      </SimpleGrid>
    </Box>
  )
}

export default ListLayout
