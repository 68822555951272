import { useToast } from '@chakra-ui/react'
import { useCreateChannel, useGetBrands, useUpdateChannel } from 'api/queries'
import { DynamicFormField } from 'components/form/types'
import { ImageUpload } from 'components/imageUpload/ImageUpload'
import { FormikProps } from 'formik'
import { TableActionMode } from 'hooks/useActionState'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { Channel } from 'types/types'

export interface UseChannelsModalProps {
  mode: TableActionMode
  onClose: () => void
  data?: Channel
  formik: FormikProps<Channel>
}

export const useChannelsModal = ({ mode, onClose, data, formik }: UseChannelsModalProps) => {
  const toast = useToast()
  const createQuery = useCreateChannel()
  const updateQuery = useUpdateChannel()
  const { data: brands } = useGetBrands()

  useEffect(() => {
    if (brands?.length > 0 && isNil(formik.values.brand_id)) {
      formik.setValues({
        ...formik.values,
        brand_id: brands[0].id,
      })
    }
  }, [brands, formik])

  const mutationQuery = mode === 'edit' ? updateQuery : createQuery

  const handleOnSubmit = async (values: Channel) => {
    try {
      await mutationQuery.mutateAsync({
        ...data,
        ...values,
        brand_id: Number(values.brand_id),
      })
      toast({
        title: mode === 'edit' ? 'Channel is updated.' : 'Channel is created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const brandOptions = brands?.map((brand) => ({ label: brand.name, value: brand.id }))

  const formFields: DynamicFormField[] = [
    { id: 'name', name: 'Channel Name', type: 'input' },
    {
      id: 'logo',
      name: 'Channel Logo',
      type: 'custom',
      customComponent: () => (
        <ImageUpload
          value={formik.values.logo}
          onChange={(value: string) => formik.setFieldValue('logo', value)}
        />
      ),
    },
    {
      id: 'brand_id',
      name: 'Brand',
      type: 'select',
      options: brandOptions,
    },
  ]

  return {
    handleOnSubmit,
    isLoading: mutationQuery.isPending,
    formFields,
    brandOptions,
  }
}
