import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import React from 'react'
import { GenericTableProps } from './types'
import { useInfiniteScroll } from './useInfiniteScroll'

const GenericTable = ({
  title,
  columns,
  data,
  renderActionColumn,
  fetchMoreData,
  hasMore,
  isLoading,
}: GenericTableProps & { fetchMoreData: () => void; hasMore: boolean; isLoading: boolean }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

  const scrollRef = useInfiniteScroll({ fetchMoreData, hasMore, isLoading })

  const renderRows = () =>
    data.map((row, rowIndex) => (
      <Tr key={rowIndex}>
        {columns.map((column, columnIndex) => (
          <Td key={columnIndex} borderColor={borderColor}>
            {column.id === 'actions' ? (
              renderActionColumn(row)
            ) : (
              <Text color={textColor} fontSize='sm'>
                {typeof column.accessor === 'function'
                  ? column.accessor(row)
                  : row[column.accessor]}
              </Text>
            )}
          </Td>
        ))}
      </Tr>
    ))

  const renderHeaders = () => (
    <Thead>
      <Tr>
        {columns.map((column, columnIndex) => (
          <Th key={columnIndex} borderColor={borderColor}>
            <Text fontSize='sm' color='gray.400'>
              {column.Header}
            </Text>
          </Th>
        ))}
      </Tr>
    </Thead>
  )

  return (
    <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%' p='25px'>
        {title}
      </Text>
      <Box ref={scrollRef} overflowY='auto' maxHeight='500px'>
        <Table variant='simple' colorScheme='gray'>
          {renderHeaders()}
          <Tbody>{renderRows()}</Tbody>
        </Table>
        {isLoading && (
          <Box textAlign='center' py={4}>
            <Spinner color='blue.500' />
          </Box>
        )}
      </Box>
    </Card>
  )
}

export default GenericTable
